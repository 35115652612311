const actions = {
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_ERROR: 'GET_USERS_ERROR',
  GET_USER_DETAILS_REQUEST: 'GET_USER_DETAILS_REQUEST',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_ERROR: 'GET_USER_DETAILS_ERROR',
  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',

  ADMIN_BLOCK_USER_REQUEST: 'ADMIN_BLOCK_USER_REQUEST',
  ADMIN_BLOCK_USER_SUCCESS: 'ADMIN_BLOCK_USER_SUCCESS',
  ADMIN_UNBLOCK_USER_SUCCESS: 'ADMIN_UNBLOCK_USER_SUCCESS',
  ADMIN_BLOCK_USER_ERROR: 'ADMIN_BLOCK_USER_ERROR',

  CLEAR_ERRORS: 'CLEAR_ERRORS',
  getUsers: ({
    page,
    queryProperty,
    queryValue,
    filterProperty,
    filterValuesList,
    numberOfItems,
    sortBy,
    sortOrder,
    idToken
  }) => {
    page = !page ? '1' : page
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue
    numberOfItems = !numberOfItems ? '' : numberOfItems
    sortBy = !sortBy ? '' : sortBy
    sortOrder = !sortOrder ? '' : sortOrder

    return {
      type: actions.GET_USERS_REQUEST,
      payload: {
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken
      }
    }
  },
  getUser: ({ id, idToken }) => ({
    type: actions.GET_USER_DETAILS_REQUEST,
    payload: { id, idToken }
  }),
  deleteUser: ({ id, idToken }) => ({
    type: actions.DELETE_USER_REQUEST,
    payload: { id, idToken }
  }),
  userBlockUnBlock: ({ id, blockUnBlock, idToken }) => ({
    type: actions.ADMIN_BLOCK_USER_REQUEST,
    payload: { id, blockUnBlock, idToken }
  }),
  cleanupErrors: () => ({
    type: actions.CLEAR_ERRORS
  })
}
export default actions
