import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import axios from 'axios'
import { message } from 'antd'
import { apiURL } from '../../library/helpers/apiURL'
import actions from './actions'

export function* createRoleRequest () {
  yield takeEvery(actions.CREATE_ROLE_REQUEST, function* ({ payload }) {
    const { roles, idToken } = payload
    const createRoleurl = apiURL + 'createRole '

    try {
      let options = {
        method: 'post',
        data: { roles },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: createRoleurl
      }
      const response = yield call(() => axios(options))

      console.log(response)

      yield put({
        type: actions.CREATE_ROLE_SUCCESS,
        roleToken: response.data.roleToken
      })
    } catch (error) {
      console.log(error.response.data)
      yield put({ type: actions.CREATE_ROLE_ERROR })
    }
  })
}

export function* getAdmins () {
  yield takeEvery(actions.GET_ADMINS_REQUEST, function* ({ payload }) {
    const {
      page,
      queryProperty,
      queryValue,
      filterProperty,
      filterValuesList,
      numberOfItems,
      sortBy,
      sortOrder,
      idToken
    } = payload

    const getAdminsurl = apiURL + 'getAdmins '

    try {
      let options = {
        method: 'post',
        data: {
          page,
          queryProperty,
          queryValue,
          filterProperty,
          filterValuesList,
          numberOfItems,
          sortBy,
          sortOrder
        },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getAdminsurl
      }
      const response = yield call(() => axios(options))

      console.log(response)

      yield put({
        type: actions.GET_ADMINS_SUCCESS,
        adminsList: response.data.adminsList,
        total: response.data.usersTotal,
        page,
        // queryProperty,
        // queryValue,
        // filterProperty,
        // filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder
      })
    } catch (error) {
      console.log(error.response.data)
      yield put({ type: actions.GET_ADMINS_ERROR })
    }
  })
}

export function* getAdmin () {
  yield takeEvery(actions.GET_ADMIN_DETAILS_REQUEST, function* ({ payload }) {
    const { id, idToken } = payload

    const getAdminsurl = apiURL + id

    try {
      let options = {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getAdminsurl
      }
      const response = yield call(() => axios(options))

      console.log(response.data)

      yield put({
        type: actions.GET_ADMIN_DETAILS_SUCCESS,
        adminDetails: response.data
      })
    } catch (error) {
      console.log(error.response.data)
      yield put({ type: actions.GET_ADMIN_DETAILS_ERROR })
    }
  })
}
export function* getProfile () {
  yield takeEvery(actions.GET_USER_PROFILE_REQUEST, function* ({ payload }) {
    const { idToken } = payload
    console.log('payload', payload)
    const meUrl = apiURL + 'me'

    try {
      let options = {
        method: 'get',
        url: meUrl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
      const response = yield call(() => axios(options))

      console.log(response)

      yield put({
        type: actions.GET_USER_PROFILE_SUCCESS,
        userProfile: response.data
      })
    } catch (error) {
      console.log(error.response.data)
      yield put({
        type: actions.GET_USER_PROFILE_ERROR,
        error: error.response.data ? error.response.data : 'An error occured'
      })
    }
  })
}
export function* deleteAdmin () {
  yield takeEvery(actions.DELETE_ADMIN_REQUEST, function* ({ payload }) {
    const { id, idToken } = payload

    const getAdminsurl = apiURL + id

    try {
      let options = {
        method: 'delete',
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getAdminsurl
      }
      const response = yield call(() => axios(options))

      console.log(response.data)

      yield put({
        type: actions.DELETE_ADMIN_SUCCESS,
        adminDetails: response.data
      })
    } catch (error) {
      console.log(error.response.data)
      yield put({ type: actions.DELETE_ADMIN_ERROR })
    }
  })
}
export function* updateNameAndEmail () {
  yield takeEvery(
    actions.UPDATE_NAME_AND_EMAIL_REQUEST,
    function* ({ payload }) {
      const { name, email, idToken } = payload
      console.log('payload', payload)
      const updateUrl = apiURL + 'updateNameAndEmail'

      try {
        let options = {
          method: 'post',
          url: updateUrl,
          headers: {
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },

          data: {
            name,
            email
          }
        }

        const response = yield call(() => axios(options))
        if (response.status == 200) {
          message.success(response.data.message)
          yield put({
            type: actions.UPDATE_NAME_AND_EMAIL_SUCCESS,
            updateUserProfile: { name, email }
          })
        }
      } catch (error) {
        message.error(error.response.data.message)
        yield put({
          type: actions.UPDATE_NAME_AND_EMAIL_ERROR,
          adminRequestError: error.response.data
            ? error.response.data
            : 'An error occurred'
        })
      }
    }
  )
}
export function* currentPassRequest () {
  yield takeEvery(actions.CURRENT_PASS_REQUEST, function* ({ payload }) {
    const { username, password } = payload
    const loginurl = apiURL + 'login '
    console.log(payload)

    try {
      let options = {
        method: 'post',
        url: loginurl,
        data: { username: username, password: password }
      }
      const response = yield call(() => axios(options))

      if (response.status == 200) {
        console.log(password)
        message.success(response.data.message)
        yield put({
          type: actions.CURRENT_PASS_SUCCESS,
          oldPassword: password
        })
      }
    } catch (error) {
      console.log('error', error.response.data)
      message.error(error.response.data.message)
      yield put({
        type: actions.CURRENT_PASS_ERROR,
        error: error.response.data.message
          ? error.response.data.message
          : 'An error occured'
      })
    }
  })
}
export function* updatePassRequest () {
  yield takeEvery('UPDATE_PASS_REQUEST', function* ({ payload }) {
    const { newPassword, oldPassword, idToken } = payload

    const updatePass = apiURL + 'changePassword '
    console.log(payload)

    try {
      let options = {
        method: 'post',
        url: updatePass,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },

        data: { newPassword: newPassword, oldPassword: oldPassword, token: '' }
      }
      const response = yield call(() => axios(options))

      console.log(response)
      if (response.status == 200) {
        yield put({
          type: actions.UPDATE_PASS_SUCCESS,
          oldPassword: newPassword
        })
      }
    } catch (error) {
      console.log(error)
      yield put({ type: actions.UPDATE_PASS_ERROR })
    }
  })
}

export function* loginUserRequestNewOTP () {
  yield takeEvery(actions.LOGIN_USER_NEW_OTP_REQUEST, function* ({ payload }) {
    const { newPhone, idToken } = payload
    const newOTPRequesturl = apiURL + 'requestTokenLoginUser '
    console.log('loginUserRequestNewOTP', payload)
    try {
      let options = {
        method: 'post',
        url: newOTPRequesturl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: { newPhone }
      }
      const response = yield call(() => axios(options))

      console.log(response)
      if (response.status == 200) {
        message.success(response.data.message)
        yield put({
          type: actions.LOGIN_USER_NEW_OTP_SUCCESS,
          oldPhone: newPhone
        })
      }
    } catch (error) {
      console.log(error)
      message.error(error.response.data.message)
      yield put({
        type: actions.LOGIN_USER_NEW_OTP_ERROR,
        error: error.response.data ? error.response.data : 'An error occured'
      })
    }
  })
}
export function* updatePhoneLoginUser () {
  yield takeEvery('UPDATE_PHONE_LOGIN_USER_REQUEST', function* ({ payload }) {
    const { oldPhone, newOtp, idToken } = payload
    const updatePhoneurl = apiURL + 'updatePhoneLoginUser '
    console.log(oldPhone, newOtp)
    try {
      let options = {
        method: 'post',
        url: updatePhoneurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: { newPhone: oldPhone, token: newOtp }
      }
      const response = yield call(() => axios(options))

      console.log(response)

      if (response.status == 200) {
        message.success(response.data.message)
        yield put({
          type: actions.UPDATE_PHONE_LOGIN_USER_SUCCESS
        })
      }
    } catch (error) {
      message.error(error.response.data.message)
      console.log(error)
      yield put({
        type: actions.UPDATE_PHONE_LOGIN_USER_ERROR,
        error: error.response.data ? error.response.data : 'An error occured'
      })
    }
  })
}
export function* toUpdateAdminsProfile () {
  yield takeEvery(
    actions.TO_UPDATE_ADMIN_PROFILE_REQUEST,
    function* ({ payload }) {
      const { userId, newPhone, newPassword, newRoles, idToken } = payload
      console.log('newRoles', newRoles)
      const updatePhoneurl = apiURL + 'toUpdateAdminsProfile '
      console.log(userId, newPhone, newPassword, newRoles)
      try {
        let options = {
          method: 'post',
          url: updatePhoneurl,
          headers: {
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          data: { userId, newPhone, newPassword, newRoles }
        }
        const response = yield call(() => axios(options))

        console.log(response)

        if (response.status == 200) {
          message.success('Successfully update Admin Profile.')
          yield put({
            type: actions.TO_UPDATE_ADMIN_PROFILE_SUCCESS
          })
        }
      } catch (error) {
        console.log(error)
        message.error(error.response.data.message)
        yield put({
          type: actions.TO_UPDATE_ADMIN_PROFILE_ERROR,
          error: error.response.data ? error.response.data : 'An error occured'
        })
      }
    }
  )
}
export default function* rootSaga () {
  yield all([
    fork(createRoleRequest),
    fork(getAdmins),
    fork(getAdmin),
    fork(deleteAdmin),
    fork(getProfile),
    fork(updateNameAndEmail),
    fork(currentPassRequest),
    fork(updatePassRequest),
    fork(loginUserRequestNewOTP),
    fork(updatePhoneLoginUser),
    fork(toUpdateAdminsProfile)
  ])
}
