import actions from './actions'

const initState = {
  ordersList: [],
  activeOrdersList: [],
  pendingOrdersList: [],
  completeOrderList: [],
  cancelledOrderList: [],
  userData: null,
  driverOrders: [],
  isOrderPlace: false,
  pagination: {
    current: 1,
    pageSize: 2,
    total: 0
  },
  loadingPriceEastimater: false,
  autoSuccess: false,
  manualSuccess: false,
  newOrderId: null,
  location: null,
  SpinLoader: false,
  socket: null,
  loading: false,
  orderRequestError: null,
  orderDetails: null,
  matchDriverOrder: null,
  newDriverId: null,
  activeOrder: null,
  pendingOrder: null,

  completeOrder: null,
  cancelledOrder: null,
  updateDetail: null,
  orderPrice: {
    orderId: null,
    message: null,
    priceEstimater: {
      baseFare: null,
      finalCongestionCharges: null,
      finalCostFlightOfStair: null,
      finalCostOfHelpers: null,
      finalCostPerMileCharges: null,
      finalCostPerMintCharges: null,
      finalInsurranceCharges: null,
      finalTollCharges: null,
      finalTypeFactoreCharges: null,
      maximumPrice: null,
      minimumPrice: null,
      bookingFee: null,
      driverFee: null,

      rideDistance: null,
      timeInRide: null
    }
  },
  order: {
    userId: null,
    tripStartLocation: {
      formattedAddress: null,
      city: null,
      zipcode: null,
      lat: null,
      long: null,
      floor: null,
      isFreightElevatorAvailable: null
    },
    tripEndLocation: {
      formattedAddress: null,
      city: null,
      zipcode: null,
      lat: null,
      long: null,
      floor: null,
      isFreightElevatorAvailable: null
    },
    pickUpDate: null,
    pickUpTime: null,

    isFreightInsured: null,

    description: null,
    rideType: null,

    noOfHelper: null
  },
  defaultPrice: {
    updatePriceId: null,
    baseFare: null,
    costPerMinute: null,
    peekFactorCharges: null,
    costPerMile: null,
    costPerHelper: null,
    costPerFlightOfStairs: null,
    congestionCharges: null,
    citiesWithCongestion: null,
    tollCharges: null,
    noOfTolls: null,
    timeFactorCharge: null,
    vanTypeCharge: {
      small: null,
      medium: null,
      large: null,
      xl: null
    },

    insurenceCharge: {
      freightInsured: null
    }
  },

  roleToken: '',
  currentValue: ''
}

export default function ordersReducer (state = initState, action) {
  switch (action.type) {
    case actions.NEW_USER_ORDER_REQUEST_ID:
      return {
        ...state,
        order: {
          ...state.order,

          userId: action.payload.userId
        }
      }
    case actions.CURENT_USER:
      return {
        ...state,
        currentValue: action.payload.value
      }

    case actions.NEW_PICKUP_ORDER_REQUEST:
      return {
        ...state,
        order: {
          ...state.order,
          tripStartLocation: {
            formattedAddress: action.payload.formattedAddress,
            city: action.payload.city,
            zipcode: action.payload.zipcode,
            lat: action.payload.lat,
            long: action.payload.long,
            floor: action.payload.floor,
            isFreightElevatorAvailable:
              action.payload.isFreightElevatorAvailable
          }
        }
      }
    case actions.NEW_DROPOF_ORDER_REQUEST:
      return {
        ...state,
        order: {
          ...state.order,
          tripEndLocation: {
            formattedAddress: action.payload.formattedAddress,
            city: action.payload.city,
            zipcode: action.payload.zipcode,
            lat: action.payload.lat,
            long: action.payload.long,
            floor: action.payload.floor,
            isFreightElevatorAvailable:
              action.payload.isFreightElevatorAvailable
          }
        }
      }

    case actions.NEW_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        order: {
          ...state.order,

          noOfHelper: action.payload.noOfHelper,

          isFreightInsured: action.payload.isFreightInsured,
          description: action.payload.description,
          rideType: action.payload.rideType,

          pickUpDate: action.payload.pickUpDate,
          pickUpTime: action.payload.pickUpTime
        }
      }
    case actions.CREATE_ROLE_SUCCESS: {
      return {
        ...state,
        roleToken: action.roleToken
      }
    }

    case actions.GET_ORDERS_REQUEST: {
      return {
        ...state,
        // pagination: {
        //   ...state.pagination,
        //   current: action.page,
        //   pageSize: action.numberOfItems
        // },
        loading: true
      }
    }
    case actions.GET_ACTIVE_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actions.GET_ACTIVE_ORDERS_SUCCESS: {
      return {
        ...state,
        activeOrdersList: action.activeOrdersList,
        pagination: {
          ...state.pagination,
          total: action.total,
          current: action.page,
          pageSize: action.numberOfItems
        },
        loading: false
      }
    }
    case actions.GET_DRIVER_ORDER_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actions.GET_DRIVER_ORDERS_SUCCESS: {
      return {
        ...state,
        driverOrders: action.driverOrders,

        loading: false
      }
    }
    case actions.GET_DRIVERS_REQUEST: {
      return {
        ...state,
        // pagination: {
        //   ...state.pagination,
        //   current: action.page,
        //   pageSize: action.numberOfItems
        // },
        loading: true
      }
    }

    case actions.GET_PENDING_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actions.GET_PENDING_ORDERS_SUCCESS: {
      return {
        ...state,
        pendingOrdersList: action.pendingOrdersList,
        userId: action.userId,
        driverId: action.driverId,
        pagination: {
          ...state.pagination,
          total: action.total,
          current: action.page,
          pageSize: action.numberOfItems
        },
        loading: false
      }
    }
    case actions.GET_DEFAULT_ORDER_PRICE_SUCCESS: {
      return {
        ...state,
        defaultPrice: {
          ...state.defaultPrice,
          updatePriceId: action.defaultOrderPrice?._id,
          baseFare: action.defaultOrderPrice?.baseFare,
          costPerMinute: action.defaultOrderPrice?.costPerMinute,
          peekFactorCharges: action.defaultOrderPrice?.peekFactorCharges,
          costPerMile: action.defaultOrderPrice?.costPerMile,
          costPerHelper: action.defaultOrderPrice?.costPerHelper,
          costPerFlightOfStairs:
            action.defaultOrderPrice?.costPerFlightOfStairs,
          congestionCharges: action.defaultOrderPrice?.congestionCharges,
          citiesWithCongestion: action.defaultOrderPrice?.citiesWithCongestion,
          tollCharges: action.defaultOrderPrice?.tollCharges,
          noOfTolls: action.defaultOrderPrice?.noOfTolls,
          timeFactorCharge: action.defaultOrderPrice?.timeFactorCharge,
          vanTypeCharge: {
            small: action.defaultOrderPrice?.vanTypeCharge?.small,
            medium: action.defaultOrderPrice?.vanTypeCharge?.medium,
            large: action.defaultOrderPrice?.vanTypeCharge?.large,
            xl: action.defaultOrderPrice?.vanTypeCharge?.xl
          },

          insurenceCharge: {
            freightInsured:
              action.defaultOrderPrice?.insurenceCharge?.freightInsured
          }
        },
        loading: false
      }
    }
    case actions.GET_DEFAULT_ORDER_PRICE_ERROR: {
      return {
        ...state,
        defaultPrice: {
          updatePriceId: null,
          baseFare: null,
          costPerMinute: null,
          peekFactorCharges: null,
          costPerMile: null,
          costPerHelper: null,
          costPerFlightOfStairs: null,
          congestionCharges: null,
          citiesWithCongestion: null,
          tollCharges: null,
          noOfTolls: null,
          timeFactorCharge: null,
          vanTypeCharge: {
            small: null,
            medium: null,
            large: null,
            xl: null
          },

          insurenceCharge: {
            freightInsured: null
          }
        }
      }
    }
    case actions.ADD_NEW_PRICE_SUCCESS: {
      return {
        ...state,
        defaultPrice: {
          ...state.defaultPrice,
          updatePriceId: action.data?.priceId,
          baseFare: action.data?.newPrice?.baseFare,
          costPerMinute: action.data?.newPrice?.costPerMinute,
          peekFactorCharges: action.data?.newPrice?.peekFactorCharges,
          costPerMile: action.data?.newPrice?.costPerMile,
          costPerHelper: action.data?.newPrice?.costPerHelper,
          costPerFlightOfStairs: action.data?.newPrice?.costPerFlightOfStairs,
          congestionCharges: action.data?.newPrice?.congestionCharges,
          citiesWithCongestion: action.data?.newPrice?.citiesWithCongestion,
          tollCharges: action.data?.newPrice?.tollCharges,
          noOfTolls: action.data?.newPrice?.noOfTolls,
          timeFactorCharge: action.data?.newPrice?.timeFactorCharge,
          vanTypeCharge: {
            small: action.data?.newPrice?.small,
            medium: action.data?.newPrice?.medium,
            large: action.data?.newPrice?.large,
            xl: action.data?.newPrice?.xl
          },

          insurenceCharge: {
            freightInsured: action.data?.newPrice?.freightInsured
          }
        }
      }
    }
    case actions.ADD_NEW_PRICE_ERROR: {
      return {
        ...state,
        defaultPrice: {
          updatePriceId: null,
          baseFare: null,
          costPerMinute: null,
          peekFactorCharges: null,
          costPerMile: null,
          costPerHelper: null,
          costPerFlightOfStairs: null,
          congestionCharges: null,
          citiesWithCongestion: null,
          tollCharges: null,
          noOfTolls: null,
          timeFactorCharge: null,
          vanTypeCharge: {
            small: null,
            medium: null,
            large: null,
            xl: null
          },

          insurenceCharge: {
            freightInsured: null
          }
        }
      }
    }

    case actions.UPDATE_PRICE_SUCCESS: {
      return {
        ...state,
        defaultPrice: {
          ...state.defaultPrice,
          updatePriceId: action.data?.priceId,
          baseFare: action.data?.newPrice?.baseFare,
          costPerMinute: action.data?.newPrice?.costPerMinute,
          peekFactorCharges: action.data?.newPrice?.peekFactorCharges,
          costPerMile: action.data?.newPrice?.costPerMile,
          costPerHelper: action.data?.newPrice?.costPerHelper,
          costPerFlightOfStairs: action.data?.newPrice?.costPerFlightOfStairs,
          congestionCharges: action.data?.newPrice?.congestionCharges,
          citiesWithCongestion: action.data?.newPrice?.citiesWithCongestion,
          tollCharges: action.data?.newPrice?.tollCharges,
          noOfTolls: action.data?.newPrice?.noOfTolls,
          timeFactorCharge: action.data?.newPrice?.timeFactorCharge,
          vanTypeCharge: {
            small: action.data?.newPrice?.small,
            medium: action.data?.newPrice?.medium,
            large: action.data?.newPrice?.large,
            xl: action.data?.newPrice?.xl
          },

          insurenceCharge: {
            freightInsured: action.data?.newPrice?.freightInsured
          }
        }
      }
    }
    case actions.UPDATE_PRICE_ERROR: {
      return {
        ...state,
        defaultPrice: {
          updatePriceId: null,
          baseFare: null,
          costPerMinute: null,
          peekFactorCharges: null,
          costPerMile: null,
          costPerHelper: null,
          costPerFlightOfStairs: null,
          congestionCharges: null,
          citiesWithCongestion: null,
          tollCharges: null,
          noOfTolls: null,
          timeFactorCharge: null,
          vanTypeCharge: {
            small: null,
            medium: null,
            large: null,
            xl: null
          },

          insurenceCharge: {
            freightInsured: null
          }
        }
      }
    }

    case actions.LOADING_PRICE_ESTIMATER: {
      return {
        ...state,

        loadingPriceEastimater: action.payload
      }
    }
    case actions.ORDER_PRICE_RREQUEST: {
      return {
        ...state,

        SpinLoader: true
      }
    }
    case actions.UPDATE_ORDER_PRICE_REQUEST: {
      return {
        ...state,

        SpinLoader: true
      }
    }

    case actions.PLACE_ORDER_DATA:
      return {
        ...state,
        order: action.payload.placeOrderObj
      }
    case actions.ORDER_PRICE_SUCCESS: {
      const { orderId, message, priceEstimater } = action.orderPrice

      return {
        ...state,
        newOrderId: orderId,

        orderPrice: {
          ...state.orderPrice,
          orderId: orderId,
          message: message,
          priceEstimater: {
            baseFare: priceEstimater.baseFare,
            finalCongestionCharges: priceEstimater.finalCongestionCharges,
            finalCostFlightOfStair: priceEstimater.finalCostFlightOfStair,
            finalCostOfHelpers: priceEstimater.finalCostOfHelpers,
            finalCostPerMileCharges: priceEstimater.finalCostPerMileCharges,
            finalCostPerMintCharges: priceEstimater.finalCostPerMintCharges,
            finalInsurranceCharges: priceEstimater.finalInsurranceCharges,
            finalTollCharges: priceEstimater.finalTollCharges,
            finalTypeFactoreCharges: priceEstimater.finalTypeFactoreCharges,

            minimumPrice: priceEstimater.minimumPrice,
            maximumPrice: priceEstimater.maximumPrice,
            bookingFee: priceEstimater.serviseCharger,
            driverFee: priceEstimater.driverCharger,

            rideDistance: priceEstimater.rideDistance,
            timeInRide: priceEstimater.timeInRide
          }
        },
        loading: false,
        loadingPriceEastimater: true,
        SpinLoader: false,
        isOrderPlace: false
      }
    }
    case actions.NEW_UPDATE_ORDER_SUCCESS: {
      const { orderId, message, priceEstimater } = action.orderPrice
      return {
        ...state,
        orderPrice: {
          ...state.orderPrice,
          orderId: orderId,
          message: message,
          priceEstimater: {
            baseFare: priceEstimater.baseFare,
            finalCongestionCharges: priceEstimater.finalCongestionCharges,
            finalCostFlightOfStair: priceEstimater.finalCostFlightOfStair,
            finalCostOfHelpers: priceEstimater.finalCostOfHelpers,
            finalCostPerMileCharges: priceEstimater.finalCostPerMileCharges,
            finalCostPerMintCharges: priceEstimater.finalCostPerMintCharges,
            finalInsurranceCharges: priceEstimater.finalInsurranceCharges,
            finalTollCharges: priceEstimater.finalTollCharges,
            finalTypeFactoreCharges: priceEstimater.finalTypeFactoreCharges,

            minimumPrice: priceEstimater.minimumPrice,
            maximumPrice: priceEstimater.maximumPrice,
            bookingFee: priceEstimater.serviseCharger,
            driverFee: priceEstimater.driverCharger,

            rideDistance: priceEstimater.rideDistance,
            timeInRide: priceEstimater.timeInRide
          }
        },
        loading: false,
        loadingPriceEastimater: true,
        SpinLoader: false
      }
    }
    case actions.NEW_UPDATE_ORDER_PRICE_REQUEST: {
      return {
        ...state,

        SpinLoader: true
      }
    }
    case actions.NEW_UPDATE_ORDER_PRICE_SUCCESS: {
      return {
        ...state,
        orderPrice: {
          ...state.orderPrice,

          priceEstimater: {
            baseFare: action.priceEstimater.baseFare,
            finalCongestionCharges:
              action.priceEstimater.finalCongestionCharges,
            finalCostFlightOfStair:
              action.priceEstimater.finalCostFlightOfStair,
            finalCostOfHelpers: action.priceEstimater.finalCostOfHelpers,
            finalCostPerMileCharges:
              action.priceEstimater.finalCostPerMileCharges,
            finalCostPerMintCharges:
              action.priceEstimater.finalCostPerMintCharges,
            finalInsurranceCharges:
              action.priceEstimater.finalInsurranceCharges,
            finalTollCharges: action.priceEstimater.finalTollCharges,
            finalTypeFactoreCharges:
              action.priceEstimater.finalTypeFactoreCharges,

            minimumPrice: action.priceEstimater.minimumPrice,
            maximumPrice: action.priceEstimater.maximumPrice,
            bookingFee: action.priceEstimater.serviseCharger,
            driverFee: action.priceEstimater.driverCharger,

            rideDistance: action.priceEstimater.rideDistance,
            timeInRide: action.priceEstimater.timeInRide
          }
        },
        loading: false,
        loadingPriceEastimater: true,
        SpinLoader: false,
        isOrderPlace: false
      }
    }

    case actions.ACTIVE_UPDATE_ORDER_PRICE_REQUEST: {
      return {
        ...state,

        SpinLoader: true
      }
    }
    case actions.ACTIVE_UPDATE_ORDER_PRICE_SUCCESS: {
      return {
        ...state,
        activeOrder: {
          ...state.activeOrder,

          estimatedPrice: {
            minimumPrice: action.priceEstimater.minimumPrice,
            maximumPrice: action.priceEstimater.maximumPrice,
            baseFare: action.priceEstimater.baseFare,
            bookingFee: action.priceEstimater.bookingFee,
            finalCostPerMileCharges:
              action.priceEstimater.finalCostPerMileCharges,
            finalCostPerMintCharges:
              action.priceEstimater.finalCostPerMintCharges,
            finalInsurranceCharges:
              action.priceEstimater.finalInsurranceCharges,
            finalCostFlightOfStair:
              action.priceEstimater.finalCostFlightOfStair,
            finalTypeFactoreCharges:
              action.priceEstimater.finalTypeFactoreCharges,
            finalCostOfHelpers: action.priceEstimater.finalCostOfHelpers,

            rideDistance: action.priceEstimater.rideDistance,
            timeInRide: action.priceEstimater.timeInRide,
            finalCongestionCharges:
              action.priceEstimater.finalCongestionCharges,
            finalTollCharges: action.priceEstimater.finalTollCharges
          }
        },
        loading: false,
        loadingPriceEastimater: true,
        SpinLoader: false
      }
    }
    case actions.PENDING_UPDATE_ORDER_PRICE_REQUEST: {
      return {
        ...state,

        SpinLoader: true
      }
    }
    case actions.PENDING_UPDATE_ORDER_PRICE_SUCCESS: {
      const { priceEstimater } = action
      return {
        ...state,
        pendingOrder: {
          ...state.pendingOrder,

          estimatedPrice: {
            baseFare: priceEstimater.baseFare,
            finalCongestionCharges: priceEstimater.finalCongestionCharges,
            finalCostFlightOfStair: priceEstimater.finalCostFlightOfStair,
            finalCostOfHelpers: priceEstimater.finalCostOfHelpers,
            finalCostPerMileCharges: priceEstimater.finalCostPerMileCharges,
            finalCostPerMintCharges: priceEstimater.finalCostPerMintCharges,
            finalInsurranceCharges: priceEstimater.finalInsurranceCharges,
            finalTollCharges: priceEstimater.finalTollCharges,
            finalTypeFactoreCharges: priceEstimater.finalTypeFactoreCharges,

            minimumPrice: priceEstimater.minimumPrice,
            maximumPrice: priceEstimater.maximumPrice,
            serviseCharger: priceEstimater.serviseCharger,
            driverCharger: priceEstimater.driverCharger,

            rideDistance: priceEstimater.rideDistance,
            timeInRide: priceEstimater.timeInRide
          }
        },
        loading: false,
        loadingPriceEastimater: true,
        SpinLoader: false
      }
    }

    case actions.SEARCH_USERS_SUCCESS: {
      return {
        ...state,
        userData: action.userData,
        loading: false
      }
    }
    case actions.SEARCH_USERS_ERROR:
      return {
        ...state,
        currentValue: '',
        userData: null,
        order: {
          ...state.order,

          userId: null
        }
      }
    case actions.REMOVE_USERS_REQUEST: {
      return {
        ...state,
        order: {
          ...state.order,

          userId: null
        },
        userData: null
      }
    }
    case actions.UPDATE_ORDER_PRICE_SUCCESS: {
      const { orderId, message, priceEstimater } = action.orderPrice
      return {
        ...state,
        orderPrice: {
          ...state.orderPrice,
          orderId: orderId,
          message: message,
          priceEstimater: {
            baseFare: priceEstimater.baseFare,
            finalCongestionCharges: priceEstimater.finalCongestionCharges,
            finalCostFlightOfStair: priceEstimater.finalCostFlightOfStair,
            finalCostOfHelpers: priceEstimater.finalCostOfHelpers,
            finalCostPerMileCharges: priceEstimater.finalCostPerMileCharges,
            finalCostPerMintCharges: priceEstimater.finalCostPerMintCharges,
            finalInsurranceCharges: priceEstimater.finalInsurranceCharges,
            finalTollCharges: priceEstimater.finalTollCharges,
            finalTypeFactoreCharges: priceEstimater.finalTypeFactoreCharges,

            minimumPrice: priceEstimater.minimumPrice,
            maximumPrice: priceEstimater.maximumPrice,
            bookingFee: priceEstimater.serviseCharger,
            driverFee: priceEstimater.driverCharger,

            rideDistance: priceEstimater.rideDistance,
            timeInRide: priceEstimater.timeInRide
          }
        },
        loading: false,
        loadingPriceEastimater: true,
        SpinLoader: false
      }
    }
    case actions.GET_COMPLETE_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actions.GET_COMPLETE_ORDERS_SUCCESS: {
      return {
        ...state,
        completeOrderList: action.completeOrderList,
        pagination: {
          ...state.pagination,
          total: action.total,
          current: action.page,
          pageSize: action.numberOfItems
        },
        loading: false
      }
    }
    case actions.GET_CANCELLED_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actions.GET_CANCELLED_ORDERS_SUCCESS: {
      return {
        ...state,
        cancelledOrderList: action.cancelledOrderList,
        pagination: {
          ...state.pagination,
          total: action.total,
          current: action.page,
          pageSize: action.numberOfItems
        },
        loading: false
      }
    }

    case actions.GET_ORDERS_SUCCESS: {
      return {
        ...state,
        ordersList: action.ordersList,
        pagination: {
          ...state.pagination,
          total: action.total,
          current: action.page,
          pageSize: action.numberOfItems
        },
        loading: false
      }
    }

    case actions.GET_ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        orderDetails: action.orderDetails
      }
    }
    case actions.GET_ACTIVE_ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        activeOrder: action.activeOrder
      }
    }
    case actions.ACTIVE_UPDATE_ORDER_REQUEST: {
      return {
        ...state,

        SpinLoader: true
      }
    }
    case actions.ACTIVE_UPDATE_ORDER_SUCCESS: {
      const { priceEstimater } = action
      return {
        ...state,
        loading: false,
        loadingPriceEastimater: true,
        SpinLoader: false,
        activeOrder: {
          ...state.activeOrder,
          estimatedPrice: {
            ...state.activeOrder.estimatedPrice,
            baseFare: priceEstimater.baseFare,
            finalCongestionCharges: priceEstimater.finalCongestionCharges,
            finalCostFlightOfStair: priceEstimater.finalCostFlightOfStair,
            finalCostOfHelpers: priceEstimater.finalCostOfHelpers,
            finalCostPerMileCharges: priceEstimater.finalCostPerMileCharges,
            finalCostPerMintCharges: priceEstimater.finalCostPerMintCharges,
            finalInsurranceCharges: priceEstimater.finalInsurranceCharges,
            finalTollCharges: priceEstimater.finalTollCharges,
            finalTypeFactoreCharges: priceEstimater.finalTypeFactoreCharges,

            minimumPrice: priceEstimater.minimumPrice,
            maximumPrice: priceEstimater.maximumPrice,
            serviseCharger: priceEstimater.serviseCharger,
            driverCharger: priceEstimater.driverCharger,

            rideDistance: priceEstimater.rideDistance,
            timeInRide: priceEstimater.timeInRide
          }
        }
      }
    }
    case actions.GET_PENDING_ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        pendingOrder: action.pendingOrder
      }
    }

    case actions.PENDING_UPDATE_ORDER_REQUEST: {
      return {
        ...state,

        SpinLoader: true
      }
    }
    case actions.PENDING_UPDATE_ORDER_SUCCESS: {
      const { priceEstimater } = action
      return {
        ...state,
        loading: false,
        loadingPriceEastimater: true,
        SpinLoader: false,
        pendingOrder: {
          ...state.pendingOrder,
          estimatedPrice: {
            ...state.pendingOrder.estimatedPrice,
            baseFare: priceEstimater.baseFare,
            finalCongestionCharges: priceEstimater.finalCongestionCharges,
            finalCostFlightOfStair: priceEstimater.finalCostFlightOfStair,
            finalCostOfHelpers: priceEstimater.finalCostOfHelpers,
            finalCostPerMileCharges: priceEstimater.finalCostPerMileCharges,
            finalCostPerMintCharges: priceEstimater.finalCostPerMintCharges,
            finalInsurranceCharges: priceEstimater.finalInsurranceCharges,
            finalTollCharges: priceEstimater.finalTollCharges,
            finalTypeFactoreCharges: priceEstimater.finalTypeFactoreCharges,

            minimumPrice: priceEstimater.minimumPrice,
            maximumPrice: priceEstimater.maximumPrice,
            serviseCharger: priceEstimater.serviseCharger,
            driverCharger: priceEstimater.driverCharger,

            rideDistance: priceEstimater.rideDistance,
            timeInRide: priceEstimater.timeInRide

            // minimumPrice: action.priceEstimater?.minimumPrice,
            // maximumPrice: action.priceEstimater?.maximumPrice,
            // baseFare: action.priceEstimater?.baseFare,
            // bookingFee: action.priceEstimater?.bookingFee,
            // finalCostPerMileCharges:
            //   action.priceEstimater?.finalCostPerMileCharges,
            // finalCostPerMintCharges:
            //   action.priceEstimater?.finalCostPerMintCharges,
            // finalInsurranceCharges:
            //   action.priceEstimater?.finalInsurranceCharges,
            // finalCostFlightOfStair:
            //   action.priceEstimater?.finalCostFlightOfStair,
            // finalTypeFactoreCharges:
            //   action.priceEstimater?.finalTypeFactoreCharges,
            // finalCostOfHelpers: action.priceEstimater?.finalCostOfHelpers,

            // rideDistance: action.priceEstimater?.rideDistance,
            // timeInRide: action.priceEstimater?.timeInRide,
            // finalCongestionCharges:
            //   action.priceEstimater?.finalCongestionCharges,
            // finalTollCharges: action.priceEstimater?.finalTollCharges
          }
        }
      }
    }
    case actions.GET_COMPLETE_ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        completeOrder: action.completeOrder
      }
    }
    case actions.GET_CANCELLED_ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        cancelledOrder: action.cancelledOrder
      }
    }
    case actions.UPDATE_SUCCESS: {
      return {
        ...state,
        updateDetail: action.updateDetail
      }
    }
    case actions.AUTO_SUCCESS: {
      return {
        ...state,
        autoSuccess: false
      }
    }
    case actions.AUTO_PLACE_ORDER_SUCCESS: {
      return {
        ...state,
        autoSuccess: true,
        isOrderPlace: true,
        currentValue: '',
        SpinLoader: false,
        orderPrice: {
          orderId: null,
          message: null,
          priceEstimater: {
            baseFare: null,
            finalCongestionCharges: null,
            finalCostFlightOfStair: null,
            finalCostOfHelpers: null,
            finalCostPerMileCharges: null,
            finalCostPerMintCharges: null,
            finalInsurranceCharges: null,
            finalTollCharges: null,
            finalTypeFactoreCharges: null,
            maximumPrice: null,
            minimumPrice: null,
            bookingFee: null,
            driverFee: null,

            rideDistance: null,
            timeInRide: null
          }
        },
        order: {
          userId: null,
          tripStartLocation: {
            formattedAddress: null,
            city: null,
            zipcode: null,
            lat: null,
            long: null,
            floor: null,
            isFreightElevatorAvailable: null
          },
          tripEndLocation: {
            formattedAddress: null,
            city: null,
            zipcode: null,
            lat: null,
            long: null,
            floor: null,
            isFreightElevatorAvailable: null
          },
          pickUpDate: null,
          pickUpTime: null,
          isFreightInsured: null,

          description: null,
          rideType: null,

          noOfHelper: null
        }
      }
    }
    case actions.AUTO_PLACE_ORDER_ERROR: {
      return {
        ...state,
        autoError: action.payload
      }
    }
    case actions.MANUAL_SUCCESS: {
      return {
        ...state,
        manualSuccess: false
      }
    }
    case actions.MANUAL_PLACE_ORDER_SUCCESS: {
      return {
        ...state,
        manualSuccess: true,
        currentValue: '',
        SpinLoader: false,
        order: {
          userId: null,
          tripStartLocation: {
            formattedAddress: null,
            city: null,
            zipcode: null,
            lat: null,
            long: null,
            floor: null,
            isFreightElevatorAvailable: null
          },
          tripEndLocation: {
            formattedAddress: null,
            city: null,
            zipcode: null,
            lat: null,
            long: null,
            floor: null,
            isFreightElevatorAvailable: null
          },
          pickUpDate: null,
          pickUpTime: null,
          isFreightInsured: null,

          description: null,
          rideType: null,

          noOfHelper: null
        },
        orderPrice: {
          orderId: null,
          message: null,
          priceEstimater: {
            minimumPrice: null,
            maximumPrice: null,
            baseFare: null,
            bookingFee: null,
            finalCostPerMileCharges: null,
            finalCostPerMintCharges: null,
            finalInsurranceCharges: null,
            finalCostFlightOfStair: null,
            finalTypeFactoreCharges: null,
            finalCostOfHelpers: null,

            rideDistance: null,
            timeInRide: null,
            finalCongestionCharges: null,
            finalTollCharges: null
          }
        }
      }
    }
    case actions.MANUAL_PLACE_ORDER_ERROR: {
      return {
        ...state,
        manualError: action.payload
      }
    }

    case actions.Edit_Active_StartLocation: {
      let { tripStartLocation } = action.payload

      return {
        ...state,
        activeOrder: {
          ...state.activeOrder,
          tripStartLocation: {
            formattedAddress: tripStartLocation.formattedAddress,
            city: tripStartLocation.city,
            zipcode: tripStartLocation.zipcode,
            lat: tripStartLocation.lat,
            long: tripStartLocation.long,
            floor: tripStartLocation.floor
          }
        }
      }
    }
    case actions.Edit_Active_EndLocation: {
      let { tripEndLocation } = action.payload
      return {
        ...state,
        activeOrder: {
          ...state.activeOrder,
          tripEndLocation: {
            formattedAddress: tripEndLocation.formattedAddress,
            city: tripEndLocation.city,
            zipcode: tripEndLocation.zipcode,
            lat: tripEndLocation.lat,
            long: tripEndLocation.long,
            floor: tripEndLocation.floor
          }
        }
      }
    }
    case actions.Edit_Active_OrderDetail: {
      let { feildsObj } = action.payload
      return {
        ...state,
        activeOrder: {
          ...state.activeOrder,

          noOfHelpersRequired: feildsObj.noOfHelpersRequired,
          rideType: feildsObj.rideType,
          orderStatus: feildsObj.orderStatus,
          description: feildsObj.description,
          date: feildsObj.date,
          time: feildsObj.time,

          isFreightInsured: feildsObj.isFreightInsured
        }
      }
    }
    case actions.Edit_Pending_StartLocation: {
      let { tripStartLocation } = action.payload

      return {
        ...state,
        pendingOrder: {
          ...state.pendingOrder,
          tripStartLocation: {
            formattedAddress: tripStartLocation.formattedAddress,
            city: tripStartLocation.city,
            zipcode: tripStartLocation.zipcode,
            lat: tripStartLocation.lat,
            long: tripStartLocation.long,
            floor: tripStartLocation.floor
          }
        }
      }
    }
    case actions.Edit_Pending_EndLocation: {
      let { tripEndLocation } = action.payload
      return {
        ...state,
        pendingOrder: {
          ...state.pendingOrder,
          tripEndLocation: {
            formattedAddress: tripEndLocation.formattedAddress,
            city: tripEndLocation.city,
            zipcode: tripEndLocation.zipcode,
            lat: tripEndLocation.lat,
            long: tripEndLocation.long,
            floor: tripEndLocation.floor
          }
        }
      }
    }
    case actions.Edit_Pending_OrderDetail: {
      let { feildsObj } = action.payload
      return {
        ...state,
        pendingOrder: {
          ...state.pendingOrder,

          noOfHelpersRequired: feildsObj.noOfHelpersRequired,
          rideType: feildsObj.rideType,
          orderStatus: feildsObj.orderStatus,
          description: feildsObj.description,
          date: feildsObj.date,
          time: feildsObj.time,

          isFreightInsured: feildsObj.isFreightInsured
        }
      }
    }
    case actions.EDIT_ORDER: {
      let { feildName, feildsObj } = action.payload

      if (feildName !== null) {
        return {
          ...state,
          orderDetails: { ...state.orderDetails, [feildName]: feildsObj }
        }
      } else {
        return {
          ...state,
          orderDetails: { ...state.orderDetails, ...feildsObj }
        }
      }
    }
    case actions.SOCKET_CONNECTION: {
      return {
        ...state,
        socket: action.payload
      }
    }
    case actions.RECEIVE_MESSAGE: {
      return {
        ...state,
        location: action.payload
      }
    }
    case actions.RECEIVE_MESSAGES: {
      return {
        ...state,
        location: action.payload
      }
    }
    case actions.MATCH_DRIVER_ORDER_REQUEST: {
      return {
        ...state,
        loading: true,
        newDriverId: null
      }
    }
    case actions.MATCH_DRIVER_ORDERS_SUCCESS: {
      return {
        ...state,
        // matchDriverOrder: action.matchDriverOrder,
        orderDetails: action.orderDetails,
        loading: false,
        newDriverId: action.newDriverId
      }
    }

    case actions.DELETE_ORDER_SUCCESS: {
      return {
        ...state,
        orderDetails: null
      }
    }

    case actions.CLEAR_ERRORS:
      return {
        ...state,
        orderRequestError: null,
        isOrderPlace: false,
        newOrderId: null
      }

    default:
      return state
  }
}
