import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import axios from 'axios'
import { message } from 'antd'
import { apiURL } from '../../library/helpers/apiURL'
import actions from './actions'

export function * getDrivers () {
  yield takeEvery(actions.GET_DRIVERS_REQUEST, function * ({ payload }) {
    const {
      page,
      queryProperty,
      queryValue,
      filterProperty,
      filterValuesList,
      numberOfItems,
      sortBy,
      sortOrder,
      idToken,
      activityStatus
    } = payload
    console.log(' driver list payload', payload)
    const getDriverssurl = apiURL + 'getAllDrivers'

    try {
      let options = {
        method: 'post',
        data: {
          page,
          queryProperty,
          queryValue,
          filterProperty,
          filterValuesList,
          numberOfItems,
          sortBy,
          sortOrder,
          activityStatus
        },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getDriverssurl
      }
      const response = yield call(() => axios(options))
      console.log('driver list response ', response)
      yield put({
        type: actions.GET_DRIVERS_SUCCESS,
        driversList: response.data.driversList,
        total: response.data.usersTotal,
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder
      })
    } catch (error) {
      yield put({ type: actions.GET_DRIVERS_ERROR })
    }
  })
}

export function * getDriver () {
  yield takeEvery(actions.GET_DRIVER_DETAILS_REQUEST, function * ({ payload }) {
    const { id, idToken } = payload

    const getDriverurl = `${apiURL}getDriverDetails/${id}`

    console.log(getDriverurl)

    try {
      let options = {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getDriverurl
      }
      const response = yield call(() => axios(options))

      console.log(response.data)

      yield put({
        type: actions.GET_DRIVER_DETAILS_SUCCESS,
        driverDetails: response.data
      })
    } catch (error) {
      console.log(error.response.data)
      yield put({ type: actions.GET_DRIVER_DETAILS_ERROR })
    }
  })
}
export function * driverBlockUnBlock () {
  yield takeEvery(actions.ADMIN_BLOCK_DRIVER_REQUEST, function * ({ payload }) {
    const { id, blockUnBlock, idToken } = payload

    const getDriverurl = `${apiURL}driverBlockUnBlock`

    try {
      let options = {
        method: 'post',
        url: getDriverurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          driverId: id,
          allowedresponses: blockUnBlock
        }
      }
      const response = yield call(() => axios(options))

      console.log(response.data)

      if (blockUnBlock === 'Block') {
        message.success(response.data.message)
        yield put({
          type: actions.ADMIN_BLOCK_DRIVER_SUCCESS
        })
      } else {
        message.success(response.data.message)
        yield put({
          type: actions.ADMIN_UNBLOCK_DRIVER_SUCCESS
        })
      }
    } catch (error) {
      console.log(error.response.data)
      message.error(error.response.data.message)
      yield put({ type: actions.ADMIN_BLOCK_DRIVER_ERROR })
    }
  })
}

export function * setDocsApprovalStatus () {
  console.log('setDocsApprovalStatus')
  yield takeEvery(actions.DOCS_APPROVAL_REQUEST, function * ({ payload }) {
    console.log(payload)
    const { id, idToken, approvalStatus, adminComment } = payload
    const getAdminsurl = `${apiURL}approveDriver/${id}`

    try {
      let options = {
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          approvalStatus: approvalStatus,
          adminComment: adminComment
        },
        url: getAdminsurl
      }
      const response = yield call(() => axios(options))
      console.log('Approve Docs data', response.data)
      yield put({
        type: actions.DOCS_APPROVAL_ACCEPTED,
        docsSuccessMsg: response.data.message
      })
    } catch (error) {
      console.log(error.response.data)
      yield put({
        type: actions.DOCS_APPROVAL_REJECTED,
        docsErrorMsg: 'Documents Rejected'
      })
    }
  })
}

export function * deleteDriver () {
  yield takeEvery(actions.DELETE_DRIVER_REQUEST, function * ({ payload }) {
    const { id, idToken } = payload
    console.log(payload)
    const deleteDriverurl = `${apiURL}driver/${id}`

    try {
      let options = {
        method: 'delete',
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: deleteDriverurl
      }
      const response = yield call(() => axios(options))

      console.log(response.data)

      yield put({
        type: actions.DELETE_DRIVER_SUCCESS,
        adminDetails: response.data
      })
    } catch (error) {
      console.log(error.response.data.message)
      yield put({ type: actions.DELETE_ADMIN_ERROR })
    }
  })
}

export function * getActiveOrders () {
  yield takeEvery(actions.GET_ACTIVE_ORDERS_REQUEST, function * ({ payload }) {
    const {
      driverId,
      page,
      queryProperty,
      queryValue,
      filterProperty,
      filterValuesList,
      numberOfItems,
      sortBy,
      sortOrder,
      idToken
    } = payload

    const getOrderssurl = apiURL + 'driverActiveOrders'

    try {
      let options = {
        method: 'post',
        data: {
          driverId,
          page,
          queryProperty,
          queryValue,
          filterProperty,
          filterValuesList,
          numberOfItems,
          sortBy,
          sortOrder
        },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getOrderssurl
      }
      const response = yield call(() => axios(options))

      yield put({
        type: actions.GET_ACTIVE_ORDERS_SUCCESS,
        activeOrdersList: response.data.orderList,
        total: response.data.usersTotal,
        page,
        numberOfItems,
        sortBy,
        sortOrder
      })
    } catch (error) {
      // yield put({ type: actions.GET_ACTIVE_ORDERS_ERROR })
    }
  })
}
export function * getPendingOrders () {
  yield takeEvery(actions.GET_PENDING_ORDERS_REQUEST, function * ({ payload }) {
    const {
      driverId,
      page,
      queryProperty,
      queryValue,
      filterProperty,
      filterValuesList,
      numberOfItems,
      sortBy,
      sortOrder,
      idToken
    } = payload

    const getOrderssurl = apiURL + 'driverPendingOrders'

    try {
      let options = {
        method: 'post',
        data: {
          driverId,
          page,
          queryProperty,
          queryValue,
          filterProperty,
          filterValuesList,
          numberOfItems,
          sortBy,
          sortOrder
        },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getOrderssurl
      }
      const response = yield call(() => axios(options))

      yield put({
        type: actions.GET_PENDING_ORDERS_SUCCESS,
        pendingOrdersList: response.data.orderList,
        total: response.data.usersTotal,
        page,
        numberOfItems,
        sortBy,
        sortOrder
      })
    } catch (error) {
      console.log(error.response)
      // yield put({ type: actions.GET_ACTIVE_ORDERS_ERROR })
    }
  })
}
export function * getCompleteOrders () {
  yield takeEvery(actions.GET_COMPLETE_ORDERS_REQUEST, function * ({
    payload
  }) {
    const {
      driverId,
      page,
      queryProperty,
      queryValue,
      filterProperty,
      filterValuesList,
      numberOfItems,
      sortBy,
      sortOrder,
      idToken
    } = payload

    const getOrderssurl = apiURL + 'driverCompleteOrders'

    try {
      let options = {
        method: 'post',
        data: {
          driverId,
          page,
          queryProperty,
          queryValue,
          filterProperty,
          filterValuesList,
          numberOfItems,
          sortBy,
          sortOrder
        },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getOrderssurl
      }
      const response = yield call(() => axios(options))

      yield put({
        type: actions.GET_COMPLETE_ORDERS_SUCCESS,
        completeOrderList: response.data.orderList,
        total: response.data.usersTotal,
        page,
        numberOfItems,
        sortBy,
        sortOrder
      })
    } catch (error) {
      // yield put({ type: actions.GET_ACTIVE_ORDERS_ERROR })
    }
  })
}
export function * getCancelledOrders () {
  yield takeEvery(actions.GET_CANCELLED_ORDERS_REQUEST, function * ({
    payload
  }) {
    const {
      driverId,
      page,
      queryProperty,
      queryValue,
      filterProperty,
      filterValuesList,
      numberOfItems,
      sortBy,
      sortOrder,
      idToken
    } = payload

    const getOrderssurl = apiURL + 'driverCancelOrders'

    try {
      let options = {
        method: 'post',
        data: {
          driverId,
          page,
          queryProperty,
          queryValue,
          filterProperty,
          filterValuesList,
          numberOfItems,
          sortBy,
          sortOrder
        },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getOrderssurl
      }
      const response = yield call(() => axios(options))
      console.log('cancelledOrder', response.data.orderList)
      message.success(response.data.message)
      yield put({
        type: actions.GET_CANCELLED_ORDERS_SUCCESS,
        cancelledOrderList: response.data.orderList,
        total: response.data.usersTotal,
        page,
        numberOfItems,
        sortBy,
        sortOrder
      })
    } catch (error) {
      message.error(error.response.data.message)
      // yield put({ type: actions.GET_ACTIVE_ORDERS_ERROR })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getDrivers),
    fork(getDriver),
    fork(deleteDriver),
    fork(setDocsApprovalStatus),
    fork(driverBlockUnBlock)

    // fork(getActiveOrders),
    // fork(getPendingOrders),
    // fork(getCompleteOrders),
    // fork(getCancelledOrders)
  ])
}
