import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import axios from 'axios'
import { message } from 'antd'
import moment from 'moment'
import { apiURL } from '../../library/helpers/apiURL'
import actions from './actions'
import history from '../../library/helpers/history'
export function* getOrders () {
  yield takeEvery(actions.GET_ORDERS_REQUEST, function* ({ payload }) {
    const {
      page,
      queryProperty,
      queryValue,
      filterProperty,
      filterValuesList,
      numberOfItems,
      sortBy,
      sortOrder,
      idToken
    } = payload

    const getOrderssurl = apiURL + 'getAllOrders'

    try {
      let options = {
        method: 'post',
        data: {
          page,
          queryProperty,
          queryValue,
          filterProperty,
          filterValuesList,
          numberOfItems,
          sortBy,
          sortOrder
        },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getOrderssurl
      }
      const response = yield call(() => axios(options))

      yield put({
        type: actions.GET_ORDERS_SUCCESS,
        ordersList: response.data.orderList,
        total: response.data.usersTotal,
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder
      })
    } catch (error) {
      yield put({ type: actions.GET_ORDERS_ERROR })
    }
  })
}
export function* getActiveOrders () {
  yield takeEvery(actions.GET_ACTIVE_ORDERS_REQUEST, function* ({ payload }) {
    const {
      userId,
      driverId,
      page,
      queryProperty,
      queryValue,
      filterProperty,
      filterValuesList,
      numberOfItems,
      sortBy,
      sortOrder,
      idToken
    } = payload

    const getOrderssurl = apiURL + 'allActiveOrders'

    try {
      let options = {
        method: 'post',
        data: {
          userId,
          driverId,
          page,
          queryProperty,
          queryValue,
          filterProperty,
          filterValuesList,
          numberOfItems,
          sortBy,
          sortOrder
        },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getOrderssurl
      }
      const response = yield call(() => axios(options))

      yield put({
        type: actions.GET_ACTIVE_ORDERS_SUCCESS,
        activeOrdersList: response.data.orderList,
        total: response.data.ordersTotal,
        page,
        numberOfItems,
        sortBy,
        sortOrder
      })
    } catch (error) {
      // yield put({ type: actions.GET_ACTIVE_ORDERS_ERROR })
    }
  })
}
export function* getPendingOrders () {
  yield takeEvery(actions.GET_PENDING_ORDERS_REQUEST, function* ({ payload }) {
    const {
      userId,
      driverId,
      page,
      queryProperty,
      queryValue,
      filterProperty,
      filterValuesList,
      numberOfItems,
      sortBy,
      sortOrder,
      idToken
    } = payload

    const getOrderssurl = apiURL + 'allPendingOrders'

    try {
      let options = {
        method: 'post',
        data: {
          userId,
          driverId,
          page,
          queryProperty,
          queryValue,
          filterProperty,
          filterValuesList,
          numberOfItems,
          sortBy,
          sortOrder
        },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getOrderssurl
      }
      const response = yield call(() => axios(options))

      yield put({
        type: actions.GET_PENDING_ORDERS_SUCCESS,
        pendingOrdersList: response.data.orderList,
        total: response.data.ordersTotal,
        userId,
        driverId,
        page,
        numberOfItems,
        sortBy,
        sortOrder
      })
    } catch (error) {
      // yield put({ type: actions.GET_ACTIVE_ORDERS_ERROR })
    }
  })
}
export function* getDefaultPrice () {
  yield takeEvery(
    actions.GET_DEFAULT_ORDER_PRICE_REQUEST,
    function* ({ payload }) {
      const { idToken } = payload

      const getOrderssurl = apiURL + 'getDefaultPrice'

      try {
        let options = {
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          url: getOrderssurl
        }

        const response = yield call(() => axios(options))

        yield put({
          type: actions.GET_DEFAULT_ORDER_PRICE_SUCCESS,
          defaultOrderPrice: response.data
        })
      } catch (error) {
        yield put({ type: actions.GET_DEFAULT_ORDER_PRICE_ERROR })
      }
    }
  )
}
export function* getCompleteOrders () {
  yield takeEvery(actions.GET_COMPLETE_ORDERS_REQUEST, function* ({ payload }) {
    const {
      userId,
      driverId,
      page,
      queryProperty,
      queryValue,
      filterProperty,
      filterValuesList,
      numberOfItems,
      sortBy,
      sortOrder,
      idToken
    } = payload

    const getOrderssurl = apiURL + 'allCompleteOrders'

    try {
      let options = {
        method: 'post',
        data: {
          userId,
          driverId,
          page,
          queryProperty,
          queryValue,
          filterProperty,
          filterValuesList,
          numberOfItems,
          sortBy,
          sortOrder
        },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getOrderssurl
      }
      const response = yield call(() => axios(options))

      yield put({
        type: actions.GET_COMPLETE_ORDERS_SUCCESS,
        completeOrderList: response.data.orderList,
        total: response.data.ordersTotal,
        page,
        numberOfItems,
        sortBy,
        sortOrder
      })
    } catch (error) {
      // yield put({ type: actions.GET_ACTIVE_ORDERS_ERROR })
    }
  })
}
export function* getCancelledOrders () {
  yield takeEvery(
    actions.GET_CANCELLED_ORDERS_REQUEST,
    function* ({ payload }) {
      const {
        userId,
        driverId,
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken
      } = payload

      const getOrderssurl = apiURL + 'allCancelOrders'

      try {
        let options = {
          method: 'post',
          data: {
            userId,
            driverId,
            page,
            queryProperty,
            queryValue,
            filterProperty,
            filterValuesList,
            numberOfItems,
            sortBy,
            sortOrder
          },
          headers: {
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          url: getOrderssurl
        }
        const response = yield call(() => axios(options))

        yield put({
          type: actions.GET_CANCELLED_ORDERS_SUCCESS,
          cancelledOrderList: response.data.orderList,
          total: response.data.ordersTotal,
          page,
          numberOfItems,
          sortBy,
          sortOrder
        })
      } catch (error) {
        // yield put({ type: actions.GET_ACTIVE_ORDERS_ERROR })
      }
    }
  )
}

export function* getOrder () {
  yield takeEvery(actions.GET_ORDER_DETAILS_REQUEST, function* ({ payload }) {
    const { id, idToken } = payload

    const getOrderurl = `${apiURL}orderDetails/${id}`

    try {
      let options = {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getOrderurl
      }
      const response = yield call(() => axios(options))

      yield put({
        type: actions.GET_ORDER_DETAILS_SUCCESS,
        orderDetails: response.data
      })
    } catch (error) {
      yield put({ type: actions.GET_ORDER_DETAILS_ERROR })
    }
  })
}
export function* getActiveOrder () {
  yield takeEvery(
    actions.GET_ACTIVE_ORDER_DETAILS_REQUEST,
    function* ({ payload }) {
      const { id, idToken } = payload

      const getOrderurl = `${apiURL}orderDetails/${id}`

      try {
        let options = {
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          url: getOrderurl
        }
        const response = yield call(() => axios(options))

        yield put({
          type: actions.GET_ACTIVE_ORDER_DETAILS_SUCCESS,
          activeOrder: response.data
        })
      } catch (error) {
        yield put({ type: actions.GET_ACTIVE_ORDER_DETAILS_ERROR })
      }
    }
  )
}
export function* getPendingOrder () {
  yield takeEvery(
    actions.GET_PENDING_ORDER_DETAILS_REQUEST,
    function* ({ payload }) {
      const { id, idToken } = payload

      const getOrderurl = `${apiURL}getPendingOrderDetails/${id}`

      try {
        let options = {
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          url: getOrderurl
        }
        const response = yield call(() => axios(options))

        yield put({
          type: actions.GET_PENDING_ORDER_DETAILS_SUCCESS,
          pendingOrder: response.data
        })
      } catch (error) {
        yield put({ type: actions.GET_PENDING_ORDER_DETAILS_ERROR })
      }
    }
  )
}
export function* getCompleteOrder () {
  yield takeEvery(
    actions.GET_COMPLETE_ORDER_DETAILS_REQUEST,
    function* ({ payload }) {
      const { id, idToken } = payload

      const getOrderurl = `${apiURL}orderDetails/${id}`

      try {
        let options = {
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          url: getOrderurl
        }
        const response = yield call(() => axios(options))

        yield put({
          type: actions.GET_COMPLETE_ORDER_DETAILS_SUCCESS,
          completeOrder: response.data
        })
      } catch (error) {
        yield put({ type: actions.GET_COMPLETE_ORDER_DETAILS_ERROR })
      }
    }
  )
}
export function* getCancelledOrder () {
  yield takeEvery(
    actions.GET_CANCELLED_ORDER_DETAILS_REQUEST,
    function* ({ payload }) {
      const { id, idToken } = payload

      const getOrderurl = `${apiURL}orderDetails/${id}`

      try {
        let options = {
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          url: getOrderurl
        }
        const response = yield call(() => axios(options))

        yield put({
          type: actions.GET_CANCELLED_ORDER_DETAILS_SUCCESS,
          cancelledOrder: response.data
        })
      } catch (error) {
        yield put({ type: actions.GET_CANNCELLED_ORDER_DETAILS_ERROR })
      }
    }
  )
}
export function* newUpdateOrders () {
  yield takeEvery(actions.NEW_UPDATE_ORDER_REQUEST, function* ({ payload }) {
    const { newUpdateOrder, idToken } = payload
    const toISOFormat = (pickUpDate, pickUpTime) => {
      let newdate = moment(pickUpDate).format('DD.MM.YYYY')
      let newtime = moment(pickUpTime, 'HH:mm').format('hh:mm:ss')
      let dateAndtime = newdate + ' ' + newtime
      let parsedDate = moment(dateAndtime, 'DD.MM.YYYY H:mm:ss')
      let finalDate = parsedDate.toISOString()
      return finalDate
    }
    let pickUpDateAndTime = toISOFormat(
      newUpdateOrder.pickUpDate,
      newUpdateOrder.pickUpTime
    )
    let newOrderData = {
      order_Id: newUpdateOrder.order_Id,
      userId: newUpdateOrder.userId,
      tripStartLocation: {
        formattedAddress: newUpdateOrder.tripStartLocation.formattedAddress,
        city: newUpdateOrder.tripStartLocation.city,
        zipcode: newUpdateOrder.tripStartLocation.zipcode,
        lat: newUpdateOrder.tripStartLocation.lat,
        long: newUpdateOrder.tripStartLocation.long,
        floor: newUpdateOrder.tripStartLocation.floor,
        isFreightElevatorAvailable:
          newUpdateOrder.tripStartLocation.isFreightElevatorAvailable
      },
      tripEndLocation: {
        formattedAddress: newUpdateOrder.tripEndLocation.formattedAddress,
        city: newUpdateOrder.tripEndLocation.city,
        zipcode: newUpdateOrder.tripEndLocation.zipcode,
        lat: newUpdateOrder.tripEndLocation.lat,
        long: newUpdateOrder.tripEndLocation.long,
        floor: newUpdateOrder.tripEndLocation.floor,
        isFreightElevatorAvailable:
          newUpdateOrder.tripEndLocation.isFreightElevatorAvailable
      },

      // rideType: newUpdateOrder.rideType,
      rideType: newUpdateOrder.rideType,
      isFreightInsured: newUpdateOrder.isFreightInsured,
      description: newUpdateOrder.description,
      noOfHelpersRequired: newUpdateOrder.noOfHelpersRequired,

      pickUpDateAndTime: pickUpDateAndTime
    }

    const orderurl = `${apiURL}orderPriceCalculation`

    try {
      let options = {
        method: 'post',
        url: orderurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },

        data: newOrderData
      }

      const response = yield call(() => axios(options))

      if (response.status == 200) {
        yield put({
          type: actions.NEW_UPDATE_ORDER_SUCCESS,
          orderPrice: response.data
        })
      }
    } catch (error) {
      message.error(error.response.data.message)
      yield put({
        type: actions.NEW_UPDATE_ORDER_ERROR,
        payload:
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occured'
      })
    }
  })
}

export function* activeUpdateOrders () {
  yield takeEvery(actions.ACTIVE_UPDATE_ORDER_REQUEST, function* ({ payload }) {
    const { newUpdateOrder, idToken } = payload
    const toISOFormat = (pickUpDate, pickUpTime) => {
      let newdate = moment(pickUpDate).format('DD/MM/YYYY')
      let newtime = moment(pickUpTime, 'HH:mm').format('hh:mm:ss')
      let dateAndtime = newdate + ' ' + newtime
      let parsedDate = moment(dateAndtime, 'DD.MM.YYYY H:mm:ss')
      let finalDate = parsedDate.toISOString()
      return finalDate
    }
    let pickUpDateAndTime = toISOFormat(
      newUpdateOrder.pickUpDate,
      newUpdateOrder.pickUpTime
    )
    let newOrder = {
      ...newUpdateOrder,
      pickUpDateAndTime
    }

    const orderurl = `${apiURL}orderPriceCalculation`

    try {
      let options = {
        method: 'post',
        url: orderurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },

        data: newOrder
      }

      const response = yield call(() => axios(options))

      if (response.status == 200) {
        yield put({
          type: actions.ACTIVE_UPDATE_ORDER_SUCCESS,
          priceEstimater: response.data.priceEstimater
        })
      }
    } catch (error) {
      message.error(error.response.data.message)
      yield put({
        type: actions.ACTIVE_UPDATE_ORDER_ERROR,
        payload:
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occured'
      })
    }
  })
}

export function* pendingUpdateOrders () {
  yield takeEvery(
    actions.PENDING_UPDATE_ORDER_REQUEST,
    function* ({ payload }) {
      const { newUpdateOrder, idToken } = payload
      const toISOFormat = (pickUpDate, pickUpTime) => {
        let newdate = moment(pickUpDate).format('DD/MM/YYYY')
        let newtime = moment(pickUpTime, 'HH:mm').format('hh:mm:ss')
        let dateAndtime = newdate + ' ' + newtime
        let parsedDate = moment(dateAndtime, 'DD.MM.YYYY H:mm:ss')
        let finalDate = parsedDate.toISOString()
        return finalDate
      }
      let pickUpDateAndTime = toISOFormat(
        newUpdateOrder.pickUpDate,
        newUpdateOrder.pickUpTime
      )
      let newOrder = {
        ...newUpdateOrder,
        pickUpDateAndTime
      }

      const orderurl = `${apiURL}orderPriceCalculation`

      try {
        let options = {
          method: 'post',
          url: orderurl,
          headers: {
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },

          data: newOrder
        }

        const response = yield call(() => axios(options))

        if (response.status == 200) {
          yield put({
            type: actions.PENDING_UPDATE_ORDER_SUCCESS,
            priceEstimater: response.data.priceEstimater
          })
        }
      } catch (error) {
        message.error(error.response.data.message)
        yield put({
          type: actions.PENDING_UPDATE_ORDER_ERROR,
          payload:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : 'An error occured'
        })
      }
    }
  )
}

export function* newOrderPrice () {
  yield takeEvery(
    actions.NEW_UPDATE_ORDER_PRICE_REQUEST,
    function* ({ payload }) {
      const { newPrice, id, idToken } = payload

      const orderurl = `${apiURL}customOrderPrice`

      try {
        let options = {
          method: 'post',
          url: orderurl,
          headers: {
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },

          data: { orderId: id, maximumPrice: newPrice }
        }

        const response = yield call(() => axios(options))

        if (response.status == 200) {
          message.success(response.data.message)
          yield put({
            type: actions.NEW_UPDATE_ORDER_PRICE_SUCCESS,
            priceEstimater: response.data.estimatedPrice
          })
        }
      } catch (error) {
        message.error(error.response.data.message)
        yield put({
          type: actions.NEW_UPDATE_ORDER_PRICE_ERROR,
          payload:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : 'An error occured'
        })
      }
    }
  )
}

export function* newActiveOrderPrice () {
  yield takeEvery(
    actions.ACTIVE_UPDATE_ORDER_PRICE_REQUEST,
    function* ({ payload }) {
      const { newPrice, id, idToken } = payload

      const orderurl = `${apiURL}customOrderPrice`

      try {
        let options = {
          method: 'post',
          url: orderurl,
          headers: {
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },

          data: { orderId: id, maximumPrice: newPrice }
        }

        const response = yield call(() => axios(options))

        if (response.status == 200) {
          message.success(response.data.message)
          yield put({
            type: actions.ACTIVE_UPDATE_ORDER_PRICE_SUCCESS,
            priceEstimater: response.data.estimatedPrice
          })
        }
      } catch (error) {
        message.error(error.response.data.message)
        yield put({
          type: actions.ACTIVE_UPDATE_ORDER_PRICE_ERROR,
          payload:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : 'An error occured'
        })
      }
    }
  )
}

export function* newPendingOrderPrice () {
  yield takeEvery(
    actions.PENDING_UPDATE_ORDER_PRICE_REQUEST,
    function* ({ payload }) {
      const { newPrice, id, idToken } = payload

      const orderurl = `${apiURL}customOrderPrice`

      try {
        let options = {
          method: 'post',
          url: orderurl,
          headers: {
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },

          data: { orderId: id, maximumPrice: newPrice }
        }

        const response = yield call(() => axios(options))

        if (response.status == 200) {
          message.success(response.data.message)
          yield put({
            type: actions.PENDING_UPDATE_ORDER_PRICE_SUCCESS,
            priceEstimater: response.data.estimatedPrice
          })
        }
      } catch (error) {
        message.error(error.response.data.message)
        yield put({
          type: actions.PENDING_UPDATE_ORDER_PRICE_ERROR,
          payload:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : 'An error occured'
        })
      }
    }
  )
}

export function* manualPlaceOrder () {
  yield takeEvery(actions.MANUAL_PLACE_ORDER, function* ({ payload }) {
    const { orderId, driverId, idToken } = payload

    const orderurl = `${apiURL}newPlaceOrder`

    try {
      let options = {
        method: 'post',
        url: orderurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },

        data: {
          driverId,
          orderId,
          idToken
        }
      }

      const response = yield call(() => axios(options))

      message.success(response.data.message)
      yield put({
        type: actions.MANUAL_PLACE_ORDER_SUCCESS,
        payload: response.data.message
      })
    } catch (error) {
      message.error(error.response.data.message)
      yield put({
        type: actions.MANUAL_PLACE_ORDER_ERROR,
        payload: error.response.data ? error.response.data : 'An error occured'
      })
    }
  })
}
export function* searchUsers () {
  yield takeEvery(actions.SEARCH_USERS_REQUEST, function* ({ payload }) {
    const {
      queryProperty,
      queryValue,

      idToken
    } = payload

    const getUserssurl = `${apiURL}SingleUser`

    try {
      let options = {
        method: 'post',
        data: {
          queryProperty,
          queryValue
        },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getUserssurl
      }
      const response = yield call(() => axios(options))

      if (response.status == 200) {
        yield put({
          type: actions.SEARCH_USERS_SUCCESS,
          userData: response.data.userData
        })
      }
    } catch (error) {
      message.error('User does not exists.')

      yield put({ type: actions.SEARCH_USERS_ERROR })
    }
  })
}
export function* addNewPrice () {
  yield takeEvery(actions.ADD_NEW_PRICE_REQUEST, function* ({ payload }) {
    const { newPrice, idToken } = payload

    let data = {
      updatePriceId: '',
      baseFare: newPrice?.baseFare,
      costPerMinute: newPrice?.costPerMinute,
      peekFactorCharges: newPrice?.peekFactorCharges,
      costPerMile: newPrice?.costPerMile,
      costPerHelper: newPrice?.costPerHelper,
      costPerFlightOfStairs: newPrice?.costPerFlightOfStairs,
      congestionCharges: newPrice?.congestionCharges,
      citiesWithCongestion: newPrice?.citiesWithCongestion,
      tollCharges: newPrice?.tollCharges,
      noOfTolls: newPrice?.noOfTolls,
      timeFactorCharge: newPrice?.timeFactorCharge,
      vanTypeCharge: {
        small: newPrice?.small,
        medium: newPrice?.medium,
        large: newPrice?.large,
        xl: newPrice?.xl
      },

      insurenceCharge: {
        freightInsured: newPrice?.freightInsured
      }
    }
    const orderurl = `${apiURL}defaultPrice`

    try {
      let options = {
        method: 'post',
        url: orderurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },

        data: data
      }

      const response = yield call(() => axios(options))

      if (response.status == 200) {
        message.success('New price successfully added')
        let priceId = response?.data?.updatePriceId
        yield put({
          type: actions.ADD_NEW_PRICE_SUCCESS,
          data: { newPrice, priceId }
        })
      }
    } catch (error) {
      message.error(error.response.data.message)
      yield put({
        type: actions.ADD_NEW_PRICE_ERROR,
        payload:
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occured'
      })
    }
  })
}
export function* updatePrice () {
  yield takeEvery(actions.UPDATE_PRICE_REQUEST, function* ({ payload }) {
    const { newPrice, idToken } = payload

    let data = {
      updatePriceId: newPrice?.updatePriceId,
      baseFare: newPrice?.baseFare,
      costPerMinute: newPrice?.costPerMinute,
      peekFactorCharges: newPrice?.peekFactorCharges,
      costPerMile: newPrice?.costPerMile,
      costPerHelper: newPrice?.costPerHelper,
      costPerFlightOfStairs: newPrice?.costPerFlightOfStairs,
      congestionCharges: newPrice?.congestionCharges,
      citiesWithCongestion: newPrice?.citiesWithCongestion,
      tollCharges: newPrice?.tollCharges,
      noOfTolls: newPrice?.noOfTolls,
      timeFactorCharge: newPrice?.timeFactorCharge,
      vanTypeCharge: {
        small: newPrice?.small,
        medium: newPrice?.medium,
        large: newPrice?.large,
        xl: newPrice?.xl
      },

      insurenceCharge: {
        freightInsured: newPrice?.freightInsured
      }
    }
    const orderurl = `${apiURL}defaultPrice`

    try {
      let options = {
        method: 'post',
        url: orderurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },

        data: data
      }

      const response = yield call(() => axios(options))

      if (response.status == 200) {
        message.success('update price successfully added')
        let priceId = response?.data?.updatePriceId
        yield put({
          type: actions.UPDATE_PRICE_SUCCESS,
          data: { newPrice, priceId }
        })
      }
    } catch (error) {
      message.error(error.response.data.message)
      yield put({
        type: actions.UPDATE_PRICE_ERROR,
        payload:
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occured'
      })
    }
  })
}

export function* orderPriceCalculation () {
  yield takeEvery(actions.ORDER_PRICE_RREQUEST, function* ({ payload }) {
    const { newOrder, idToken } = payload

    const toISOFormat = (pickUpDate, pickUpTime) => {
      let newdate = moment(pickUpDate).format('DD.MM.YYYY')
      let newtime = moment(pickUpTime, 'HH:mm').format('hh:mm:ss')
      let dateAndtime = newdate + ' ' + newtime
      let parsedDate = moment(dateAndtime, 'DD.MM.YYYY H:mm:ss')
      let finalDate = parsedDate.toISOString()
      return finalDate
    }
    let pickUpDateAndTime = toISOFormat(
      newOrder.pickUpDate,
      newOrder.pickUpTime
    )
    let newOrderData = {
      order_Id: newOrder.orderId,
      userId: newOrder.userId,
      tripStartLocation: {
        formattedAddress: newOrder.tripStartLocation.formattedAddress,
        city: newOrder.tripStartLocation.city,
        zipcode: newOrder.tripStartLocation.zipcode,
        lat: newOrder.tripStartLocation.lat,
        long: newOrder.tripStartLocation.long,
        floor: newOrder.tripStartLocation.floor,
        isFreightElevatorAvailable:
          newOrder.tripStartLocation.isFreightElevatorAvailable
      },
      tripEndLocation: {
        formattedAddress: newOrder.tripEndLocation.formattedAddress,
        city: newOrder.tripEndLocation.city,
        zipcode: newOrder.tripEndLocation.zipcode,
        lat: newOrder.tripEndLocation.lat,
        long: newOrder.tripEndLocation.long,
        floor: newOrder.tripEndLocation.floor,
        isFreightElevatorAvailable:
          newOrder.tripEndLocation.isFreightElevatorAvailable
      },

      rideType: newOrder.rideType,
      isFreightInsured: newOrder.isFreightInsured,

      description: newOrder.description,
      noOfHelpersRequired: newOrder.noOfHelper,

      pickUpDateAndTime: pickUpDateAndTime
    }

    const orderurl = `${apiURL}orderPriceCalculation`

    try {
      let options = {
        method: 'post',
        url: orderurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },

        data: newOrderData
      }

      const response = yield call(() => axios(options))

      if (response.status == 200) {
        yield put({
          type: actions.ORDER_PRICE_SUCCESS,
          orderPrice: response.data
        })
      }
    } catch (error) {
      message.error(error.response.data.message)
      yield put({
        type: actions.AUTO_PLACE_ORDER_ERROR,
        payload:
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occured'
      })
    }
  })
}
export function* updateOrderPriceCalculation () {
  yield takeEvery(actions.UPDATE_ORDER_PRICE_REQUEST, function* ({ payload }) {
    const { newOrder, idToken } = payload
    const toISOFormat = (pickUpDate, pickUpTime) => {
      let newdate = moment(pickUpDate).format('DD.MM.YYYY')
      let newtime = moment(pickUpTime, 'HH:mm').format('hh:mm:ss')
      let dateAndtime = newdate + ' ' + newtime
      let parsedDate = moment(dateAndtime, 'DD.MM.YYYY H:mm:ss')
      let finalDate = parsedDate.toISOString()
      return finalDate
    }
    let pickUpDateAndTime = toISOFormat(
      newOrder.pickUpDate,
      newOrder.pickUpTime
    )
    let newOrderData = {
      order_Id: newOrder.orderId,
      userId: newOrder.userId,
      tripStartLocation: {
        formattedAddress: newOrder.tripStartLocation.formattedAddress,
        city: newOrder.tripStartLocation.city,
        zipcode: newOrder.tripStartLocation.zipcode,
        lat: newOrder.tripStartLocation.lat,
        long: newOrder.tripStartLocation.long,
        floor: newOrder.tripStartLocation.floor,
        isFreightElevatorAvailable:
          newOrder.tripStartLocation.isFreightElevatorAvailable
      },
      tripEndLocation: {
        formattedAddress: newOrder.tripEndLocation.formattedAddress,
        city: newOrder.tripEndLocation.city,
        zipcode: newOrder.tripEndLocation.zipcode,
        lat: newOrder.tripEndLocation.lat,
        long: newOrder.tripEndLocation.long,
        floor: newOrder.tripEndLocation.floor,
        isFreightElevatorAvailable:
          newOrder.tripEndLocation.isFreightElevatorAvailable
      },

      rideType: newOrder.rideType,
      isFreightInsured: newOrder.isFreightInsured,
      description: newOrder.description,
      noOfHelpersRequired: newOrder.noOfHelper,

      pickUpDateAndTime: pickUpDateAndTime
    }

    const orderurl = `${apiURL}orderPriceCalculation`

    try {
      let options = {
        method: 'post',
        url: orderurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },

        data: newOrderData
      }

      const response = yield call(() => axios(options))

      if (response.status == 200) {
        yield put({
          type: actions.UPDATE_ORDER_PRICE_SUCCESS,
          orderPrice: response.data
        })
      }
    } catch (error) {
      message.error(error.response.data.message)
      yield put({
        type: actions.AUTO_PLACE_ORDER_ERROR,
        payload:
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occured'
      })
    }
  })
}
export function* autoPlaceOrder () {
  yield takeEvery(actions.AUTO_PLACE_ORDER, function* ({ payload }) {
    const { orderId, idToken } = payload

    const orderurl = `${apiURL}newPlaceOrder`

    try {
      let options = {
        method: 'post',
        url: orderurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },

        data: { orderId }
      }

      const response = yield call(() => axios(options))

      if (response.status === 200) {
        message.success(response.data.message)

        yield put({
          type: actions.AUTO_PLACE_ORDER_SUCCESS,
          payload: message
        })
      }
    } catch (error) {
      message.error(error.response.data.message)

      yield put({
        type: actions.AUTO_PLACE_ORDER_ERROR,
        payload:
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occured'
      })
    }
  })
}
export function* deleteAdmin () {
  yield takeEvery(actions.DELETE_ADMIN_REQUEST, function* ({ payload }) {
    const { id, idToken } = payload

    const delOrderurl = apiURL + id

    try {
      let options = {
        method: 'delete',
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: delOrderurl
      }
      const response = yield call(() => axios(options))

      yield put({
        type: actions.DELETE_ORDER_SUCCESS,
        orderDetails: response.data
      })
    } catch (error) {
      yield put({ type: actions.DELETE_ORDER_ERROR })
    }
  })
}

export function* matchDriver () {
  yield takeEvery(actions.MATCH_DRIVER_ORDER_REQUEST, function* ({ payload }) {
    const { id, driverId, idToken } = payload

    let orderId = id
    const delOrderurl = `${apiURL}matchDriver/${orderId}`

    try {
      let options = {
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: { driverId },
        url: delOrderurl
      }
      const response = yield call(() => axios(options))

      if (response.status === 200) {
        message.success('New Driver added successfully')
        yield put({
          type: actions.MATCH_DRIVER_ORDERS_SUCCESS,
          orderDetails: response.data,
          newDriverId: driverId
        })
      }
    } catch (error) {
      message.error('Something went wrong')
      yield put({ type: actions.MATCH_DRIVER_ORDER_ERROR })
    }
  })
}

export function* getDriverOrder () {
  yield takeEvery(actions.GET_DRIVER_ORDER_REQUEST, function* ({ payload }) {
    const { id, idToken } = payload

    const delOrderurl = `${apiURL}driverActiveOrderList/${id}`

    try {
      let options = {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: delOrderurl
      }
      const response = yield call(() => axios(options))

      yield put({
        type: actions.GET_DRIVER_ORDERS_SUCCESS,
        driverOrders: response.data
      })
    } catch (error) {
      yield put({ type: actions.GET_DRIVER_ORDER_ERROR })
    }
  })
}
export function* cancelOrder () {
  yield takeEvery(actions.CANCEL_ORDER_REQUEST, function* ({ payload }) {
    const { orderId, adminResponse, idToken } = payload

    const cancelOrderurl = `${apiURL}cancelOrderRequest`

    try {
      let options = {
        method: 'post',
        url: cancelOrderurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },

        data: { orderId: orderId, response: adminResponse }
      }

      const response = yield call(() => axios(options))

      yield put({
        type: actions.CANCEL_ORDER_SUCCESS,
        orderDetails: response.data
      })
    } catch (error) {
      yield put({ type: actions.CANCEL_ORDER_ERROR })
    }
  })
}
export function* userCancelOrder () {
  yield takeEvery(actions.USER_CANCEL_ORDER_REQUEST, function* ({ payload }) {
    const { id, adminResponse, cancelRequest, desc, idToken } = payload

    const cancelOrderurl = `${apiURL}cancelOrderUserRequest`

    try {
      let options = {
        method: 'post',
        url: cancelOrderurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },

        data: {
          orderId: id,
          response: adminResponse,
          orderCancelRequest: cancelRequest,
          orderCancelReasion: desc
        }
      }

      const response = yield call(() => axios(options))

      yield put({
        type: actions.USER_CANCEL_ORDER_SUCCESS
        // orderDetails: response.data
      })
    } catch (error) {
      yield put({ type: actions.USERCANCEL_ORDER_ERROR })
    }
  })
}
export default function* rootSaga () {
  yield all([
    fork(getOrders),
    fork(getOrder),
    fork(searchUsers),
    fork(cancelOrder),
    fork(userCancelOrder),
    fork(getActiveOrders),
    fork(getPendingOrders),
    fork(getCompleteOrders),
    fork(getCancelledOrders),
    fork(getActiveOrder),
    fork(getPendingOrder),
    fork(newUpdateOrders),
    fork(activeUpdateOrders),
    fork(getDefaultPrice),
    fork(addNewPrice),
    fork(updatePrice),

    fork(getCompleteOrder),
    fork(getCancelledOrder),
    fork(manualPlaceOrder),
    fork(autoPlaceOrder),
    fork(getDriverOrder),
    fork(matchDriver),
    fork(orderPriceCalculation),
    fork(updateOrderPriceCalculation),
    fork(newOrderPrice),
    fork(newActiveOrderPrice),
    fork(pendingUpdateOrders),
    fork(newPendingOrderPrice)
  ])
}
