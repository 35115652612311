import defaultTheme from './default'
import customTheme from './custom'

const themes = {
  defaultTheme,
  customTheme
}

export const themeConfig = {
  topbar: 'customTheme',
  sidebar: 'customTheme',
  layout: 'customTheme',
  theme: 'customTheme'
}
export default themes
