const actions = {
  GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_ERROR: 'GET_ORDERS_ERROR',
  MANUAL_PLACE_ORDER: 'MANUAL_PLACE_ORDER',
  MANUAL_PLACE_ORDER_SUCCESS: 'MANUAL_PLACE_ORDER_SUCCESS',
  MANUAL_PLACE_ORDER_ERROR: 'MANUAL_PLACE_ORDER_ERROR',
  AUTO_PLACE_ORDER: 'AUTO_PLACE_ORDER',
  AUTO_PLACE_ORDER_SUCCESS: 'AUTO_PLACE_ORDER_SUCCESS',
  AUTO_PLACE_ORDER_ERROR: 'AUTO_PLACE_ORDER_ERROR',
  GET_ORDER_DETAILS_REQUEST: 'GET_ORDER_DETAILS_REQUEST',
  GET_ORDER_DETAILS_SUCCESS: 'GET_ORDER_DETAILS_SUCCESS',
  GET_ACTIVE_ORDERS_SUCCESS: 'GET_ACTIVE_ORDERS_SUCCESS',
  GET_ORDER_DETAILS_ERROR: 'GET_ORDER_DETAILS_ERROR',
  CANCEL_ORDER_REQUEST: 'CANCEL_ORDER_REQUEST',
  CANCEL_ORDER_SUCCESS: 'CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_ERROR: 'CANCEL_ORDER_ERROR',

  USER_CANCEL_ORDER_REQUEST: 'USER_CANCEL_ORDER_REQUEST',
  USER_CANCEL_ORDER_SUCCESS: 'USER_CANCEL_ORDER_SUCCESS',
  USERCANCEL_ORDER_ERROR: 'USER_CANCEL_ORDER_ERROR',
  AUTO_SUCCESS: 'AUTO_SUCCESS',
  MANUAL_SUCCESS: 'MANUAL_SUCCESS',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_ERROR: 'UPDATE_ERROR',
  EDIT_ORDER: 'EDIT_ORDER',
  UPDATE_ORDER: 'UPDATE_ORDER',
  GET_ACTIVE_ORDERS_REQUEST: 'GET_ACTIVE_ORDERS_REQUEST',
  GET_PENDING_ORDERS_REQUEST: 'GET_PENDING_ORDERS_REQUEST',
  GET_PENDING_ORDERS_SUCCESS: 'GET_PENDING_ORDERS_SUCCESS',
  GET_COMPLETE_ORDERS_REQUEST: 'GET_COMPLETE_ORDERS_REQUEST',
  GET_COMPLETE_ORDERS_SUCCESS: 'GET_COMPLETE_ORDERS_SUCCESS',
  GET_CANCELLED_ORDERS_REQUEST: 'GET_CANCELLED_ORDERS_REQUEST',
  GET_CANCELLED_ORDERS_SUCCESS: 'GET_CANCELLED_ORDERS_SUCCESS',
  GET_ACTIVE_ORDER_DETAILS_REQUEST: 'GET_ACTIVE_ORDER_DETAILS_REQUEST',
  GET_PENDING_ORDER_DETAILS_REQUEST: 'GET_PENDING_ORDER_DETAILS_REQUEST',
  GET_COMPLETE_ORDER_DETAILS_REQUEST: 'GET_COMPLETE_ORDER_DETAILS_REQUEST',
  GET_CANCELLED_ORDER_DETAILS_REQUEST: 'GET_CANCELLED_ORDER_DETAILS_REQUEST',
  GET_CANCELLED_ORDER_DETAILS_SUCCESS: 'GET_CANCELLED_ORDER_DETAILS_SUCCESS',
  GET_CANNCELLED_ORDER_DETAILS_ERROR: 'GET_CANNCELLED_ORDER_DETAILS_ERROR',
  GET_COMPLETE_ORDER_DETAILS_SUCCESS: 'GET_COMPLETE_ORDER_DETAILS_SUCCESS',
  GET_COMPLETE_ORDER_DETAILS_ERROR: 'GET_COMPLETE_ORDER_DETAILS_ERROR',
  GET_PENDING_ORDER_DETAILS_SUCCESS: 'GET_PENDING_ORDER_DETAILS_SUCCESS',
  GET_PENDING_ORDER_DETAILS_ERROR: 'GET_PENDING_ORDER_DETAILS_ERROR',
  GET_ACTIVE_ORDER_DETAILS_SUCCESS: 'GET_ACTIVE_ORDER_DETAILS_SUCCESS',
  GET_ACTIVE_ORDER_DETAILS_ERROR: 'GET_ACTIVE_ORDER_DETAILS_ERROR',

  GET_DRIVER_ORDER_REQUEST: 'GET_DRIVER_ORDER_REQUEST',
  GET_DRIVER_ORDERS_SUCCESS: 'GET_DRIVER_ORDERS_SUCCESS',
  GET_DRIVER_ORDER_ERROR: 'GET_DRIVER_ORDER_ERROR',
  MATCH_DRIVER_ORDER_REQUEST: 'MATCH_DRIVER_ORDER_REQUEST',
  MATCH_DRIVER_ORDERS_SUCCESS: 'MATCH_DRIVER_ORDERS_SUCCESS',
  MATCH_DRIVER_ORDER_ERROR: 'MATCH_DRIVER_ORDER_ERROR',
  Edit_Active_StartLocation: 'Edit_Active_StartLocation',
  Edit_Active_EndLocation: 'Edit_Active_EndLocation',
  Edit_Active_OrderDetail: 'Edit_Active_OrderDetail',
  Update_Active_StartLocation_REQUEST: 'Update_Active_StartLocation_REQUEST',
  Update_Active_StartLocation_SUCCESS: 'Update_Active_StartLocation_SUCCESS',
  Update_Active_StartLocation_ERROR: 'Update_Active_StartLocation_ERROR',
  Update_Active_EndLocation_REQUEST: 'Update_Active_EndLocation_REQUEST',
  Update_Active_EndLocation_SUCCESS: 'Update_Active_EndLocation_SUCCESS',
  Update_Active_EndLocation_ERROR: 'Update_Active_EndLocation_ERROR',
  Update_Active_OrderDetails_REQUEST: 'Update_Active_OrderDetails_REQUEST',
  Update_Active_OrderDetails_SUCCESS: 'Update_Active_OrderDetails_SUCCESS',
  Update_Active_OrderDetails_ERROR: 'Update_Active_OrderDetails_ERROR',
  NEW_UPDATE_ORDER_REQUEST: 'NEW_UPDATE_ORDER_REQUEST',
  NEW_UPDATE_ORDER_SUCCESS: 'NEW_UPDATE_ORDER_SUCCESS',
  NEW_UPDATE_ORDER_ERROR: 'NEW_UPDATE_ORDER_ERROR',

  ACTIVE_UPDATE_ORDER_REQUEST: 'ACTIVE_UPDATE_ORDER_REQUEST',
  ACTIVE_UPDATE_ORDER_SUCCESS: 'ACTIVE_UPDATE_ORDER_SUCCESS',
  ACTIVE_UPDATE_ORDER_ERROR: 'ACTIVE_UPDATE_ORDER_ERROR',

  PENDING_UPDATE_ORDER_REQUEST: 'PENDING_UPDATE_ORDER_REQUEST',
  PENDING_UPDATE_ORDER_SUCCESS: 'PENDING_UPDATE_ORDER_SUCCESS',
  PENDING_UPDATE_ORDER_ERROR: 'PENDING_UPDATE_ORDER_ERROR',

  NEW_UPDATE_ORDER_PRICE_REQUEST: 'NEW_UPDATE_ORDER_PRICE_REQUEST',
  NEW_UPDATE_ORDER_PRICE_SUCCESS: 'NEW_UPDATE_ORDER_PRICE_SUCCESS',
  NEW_UPDATE_ORDER_PRICE_ERROR: 'NEW_UPDATE_ORDER_PRICE_ERROR',

  ACTIVE_UPDATE_ORDER_PRICE_REQUEST: 'ACTIVE_UPDATE_ORDER_PRICE_REQUEST',
  ACTIVE_UPDATE_ORDER_PRICE_SUCCESS: 'ACTIVE_UPDATE_ORDER_PRICE_SUCCESS',
  ACTIVE_UPDATE_ORDER_PRICE_ERROR: 'ACTIVE_UPDATE_ORDER_PRICE_ERROR',

  PENDING_UPDATE_ORDER_PRICE_REQUEST: 'PENDING_UPDATE_ORDER_PRICE_REQUEST',
  PENDING_UPDATE_ORDER_PRICE_SUCCESS: 'PENDING_UPDATE_ORDER_PRICE_SUCCESS',
  PENDING_UPDATE_ORDER_PRICE_ERROR: 'PENDING_UPDATE_ORDER_PRICE_ERROR',

  Edit_Pending_StartLocation: 'Edit_Pending_StartLocation',
  Edit_Pending_EndLocation: 'Edit_Pending_EndLocation',
  Edit_Pending_OrderDetail: 'Edit_Pending_OrderDetail',
  Update_Pending_StartLocation_REQUEST: 'Update_Pending_StartLocation_REQUEST',
  Update_Pending_StartLocation_SUCCESS: 'Update_Pending_StartLocation_SUCCESS',
  Update_Pending_StartLocation_ERROR: 'Update_Pending_StartLocation_ERROR',
  Update_Pending_EndLocation_REQUEST: 'Update_Pending_EndLocation_REQUEST',
  Update_Pending_EndLocation_SUCCESS: 'Update_Pending_EndLocation_SUCCESS',
  Update_Pending_EndLocation_ERROR: 'Update_Pending_EndLocation_ERROR',
  Update_Pending_OrderDetails_REQUEST: 'Update_Pending_OrderDetails_REQUEST',
  Update_Pending_OrderDetails_SUCCESS: 'Update_Pending_OrderDetails_SUCCESS',
  Update_Pending_OrderDetails_ERROR: 'Update_Pending_OrderDetails_ERROR',
  OPEN_UPDATE_SCREEN_SUCCESS: 'OPEN_UPDATE_SCREEN_SUCCESS',
  GET_DRIVER_LIVE_LOCATION_REQUEST: 'GET_DRIVER_LIVE_LOCATION_REQUEST',
  GET_DRIVER_LIVE_LOCATION_SUCCESS: 'GET_DRIVER_LIVE_LOCATION_SUCCESS',
  GET_DRIVER_LIVE_LOCATION_ERROR: ' GET_DRIVER_LIVE_LOCATION_ERROR',
  NEXT_PAGE: 'NEXT_PAGE',
  SOCKET_CONNECTION: 'SOCKET_CONNECTION',
  RECEIVE_MESSAGE: 'RECEIVE_MESSAGE',
  RECEIVE_MESSAGES: 'RECEIVE_MESSAGES',
  ORDER_PRICE_RREQUEST: ' ORDER_PRICE_RREQUEST',
  ORDER_PRICE_SUCCESS: 'ORDER_PRICE_SUCCESS',
  UPDATE_ORDER_PRICE_REQUEST: 'UPDATE_ORDER_PRICE_REQUEST',
  UPDATE_ORDER_PRICE_SUCCESS: 'UPDATE_ORDER_PRICE_SUCCESS',
  SEARCH_USERS_REQUEST: 'SEARCH_USERS_REQUEST',
  SEARCH_USERS_SUCCESS: 'SEARCH_USERS_SUCCESS',
  GET_USERS_ERROR: 'GET_USERS_ERROR',
  REMOVE_USERS_REQUEST: 'REMOVE_USERS_REQUEST',
  NEW_USER_ORDER_REQUEST_ID: 'NEW_USER_ORDER_REQUEST_ID',
  SEARCH_USERS_ERROR: 'SEARCH_USERS_ERROR',
  NEW_PICKUP_ORDER_REQUEST: 'NEW_PICKUP_ORDER_REQUEST',
  NEW_DROPOF_ORDER_REQUEST: ' NEW_DROPOF_ORDER_REQUEST',
  NEW_ORDER_DETAILS_REQUEST: 'NEW_ORDER_DETAILS_REQUEST',
  LOADING_PRICE_ESTIMATER: 'LOADING_PRICE_ESTIMATER',
  CURENT_USER: 'CURENT_USER',

  PLACE_ORDER_DATA: 'PLACE_ORDER_DATA',

  GET_DEFAULT_ORDER_PRICE_REQUEST: 'GET_DEFAULT_ORDER_PRICE_REQUEST',
  GET_DEFAULT_ORDER_PRICE_SUCCESS: 'GET_DEFAULT_ORDER_PRICE_SUCCESS',
  GET_DEFAULT_ORDER_PRICE_ERROR: 'GET_DEFAULT_ORDER_PRICE_ERROR',

  ADD_NEW_PRICE_REQUEST: 'ADD_NEW_PRICE_REQUEST',
  ADD_NEW_PRICE_SUCCESS: 'ADD_NEW_PRICE_SUCCESS',
  ADD_NEW_PRICE_ERROR: 'ADD_NEW_PRICE_ERROR',

  UPDATE_PRICE_REQUEST: 'UPDATE_PRICE_REQUEST',
  UPDATE_PRICE_SUCCESS: 'UPDATE_PRICE_SUCCESS',
  UPDATE_PRICE_ERROR: 'UPDATE_PRICE_ERROR',

  getOrders: ({
    page,
    queryProperty,
    queryValue,
    filterProperty,
    filterValuesList,
    numberOfItems,
    sortBy,
    sortOrder,
    idToken
  }) => {
    page = !page ? '1' : page
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue
    numberOfItems = !numberOfItems ? '' : numberOfItems
    sortBy = !sortBy ? '' : sortBy
    sortOrder = !sortOrder ? '' : sortOrder

    return {
      type: actions.GET_ORDERS_REQUEST,
      payload: {
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken
      }
    }
  },
  getActiveOrders: ({
    userId,
    driverId,
    page,
    queryProperty,
    queryValue,
    filterProperty,
    filterValuesList,
    numberOfItems,
    sortBy,
    sortOrder,
    idToken
  }) => {
    page = !page ? '1' : page
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue
    numberOfItems = !numberOfItems ? '' : numberOfItems
    sortBy = !sortBy ? '' : sortBy
    sortOrder = !sortOrder ? '' : sortOrder

    return {
      type: actions.GET_ACTIVE_ORDERS_REQUEST,
      payload: {
        userId,
        driverId,
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken
      }
    }
  },

  getDefaultPrice: ({ idToken }) => {
    return {
      type: actions.GET_DEFAULT_ORDER_PRICE_REQUEST,
      payload: {
        idToken
      }
    }
  },
  getPendingOrders: ({
    userId,
    driverId,
    page,
    queryProperty,
    queryValue,
    filterProperty,
    filterValuesList,
    numberOfItems,
    sortBy,
    sortOrder,
    idToken
  }) => {
    page = !page ? '1' : page
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue
    numberOfItems = !numberOfItems ? '' : numberOfItems
    sortBy = !sortBy ? '' : sortBy
    sortOrder = !sortOrder ? '' : sortOrder

    return {
      type: actions.GET_PENDING_ORDERS_REQUEST,
      payload: {
        userId,
        driverId,
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken
      }
    }
  },
  getCompleteOrders: ({
    userId,
    driverId,
    page,
    queryProperty,
    queryValue,
    filterProperty,
    filterValuesList,
    numberOfItems,
    sortBy,
    sortOrder,
    idToken
  }) => {
    page = !page ? '1' : page
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue
    numberOfItems = !numberOfItems ? '' : numberOfItems
    sortBy = !sortBy ? '' : sortBy
    sortOrder = !sortOrder ? '' : sortOrder

    return {
      type: actions.GET_COMPLETE_ORDERS_REQUEST,
      payload: {
        userId,
        driverId,
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken
      }
    }
  },
  getCancelledOrders: ({
    userId,
    driverId,
    page,
    queryProperty,
    queryValue,
    filterProperty,
    filterValuesList,
    numberOfItems,
    sortBy,
    sortOrder,
    idToken
  }) => {
    page = !page ? '1' : page
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue
    numberOfItems = !numberOfItems ? '' : numberOfItems
    sortBy = !sortBy ? '' : sortBy
    sortOrder = !sortOrder ? '' : sortOrder

    return {
      type: actions.GET_CANCELLED_ORDERS_REQUEST,
      payload: {
        userId,
        driverId,
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken
      }
    }
  },
  searchUsers: ({
    queryProperty,
    queryValue,

    idToken
  }) => {
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue

    return {
      type: actions.SEARCH_USERS_REQUEST,
      payload: {
        queryProperty,
        queryValue,

        idToken
      }
    }
  },
  loadingPriceEastimater: visible => ({
    type: actions.LOADING_PRICE_ESTIMATER,
    payload: visible
  }),
  removeUsers: () => ({
    type: actions.REMOVE_USERS_REQUEST
  }),
  newUserId: userId => ({
    type: actions.NEW_USER_ORDER_REQUEST_ID,
    payload: { userId }
  }),
  setCurrentUser: value => ({
    type: actions.CURENT_USER,
    payload: { value }
  }),
  getActiveOrder: ({ id, idToken }) => ({
    type: actions.GET_ACTIVE_ORDER_DETAILS_REQUEST,
    payload: { id, idToken }
  }),
  getPendingOrder: ({ id, idToken }) => ({
    type: actions.GET_PENDING_ORDER_DETAILS_REQUEST,
    payload: { id, idToken }
  }),
  getLiveLocation: (id, idToken) => ({
    type: actions.GET_DRIVER_LIVE_LOCATION_REQUEST,
    payload: { id, idToken }
  }),
  getDriverOrder: ({ id, idToken }) => ({
    type: actions.GET_DRIVER_ORDER_REQUEST,
    payload: { id, idToken }
  }),
  matchDriver: (id, driverId, idToken) => ({
    type: actions.MATCH_DRIVER_ORDER_REQUEST,
    payload: { id, driverId, idToken }
  }),
  getCompleteOrder: ({ id, idToken }) => ({
    type: actions.GET_COMPLETE_ORDER_DETAILS_REQUEST,
    payload: { id, idToken }
  }),
  getCancelledOrder: ({ id, idToken }) => ({
    type: actions.GET_CANCELLED_ORDER_DETAILS_REQUEST,
    payload: { id, idToken }
  }),
  getOrder: ({ id, idToken }) => ({
    type: actions.GET_ORDER_DETAILS_REQUEST,
    payload: { id, idToken }
  }),

  cancelOrder: ({ orderId, adminResponse, idToken }) => ({
    type: actions.CANCEL_ORDER_REQUEST,
    payload: { orderId, adminResponse, idToken }
  }),
  userCancelOrder: ({ id, adminResponse, cancelRequest, desc, idToken }) => ({
    type: actions.USER_CANCEL_ORDER_REQUEST,
    payload: { id, adminResponse, cancelRequest, desc, idToken }
  }),

  newPickUpOrder: newPickUpOrder => {
    const {
      formattedAddress,
      city,
      zipcode,
      isFreightElevatorAvailable,
      lat,
      long,
      floor
    } = newPickUpOrder

    return {
      type: actions.NEW_PICKUP_ORDER_REQUEST,
      payload: {
        formattedAddress,
        city,
        zipcode,
        isFreightElevatorAvailable,
        lat,
        long,
        floor
      }
    }
  },
  newDropOffOrder: newDropOffOrder => {
    const {
      formattedAddress,
      city,
      zipcode,
      isFreightElevatorAvailable,
      lat,
      long,
      floor
    } = newDropOffOrder
    return {
      type: actions.NEW_DROPOF_ORDER_REQUEST,
      payload: {
        formattedAddress,
        city,
        zipcode,
        isFreightElevatorAvailable,
        lat,
        long,
        floor
      }
    }
  },
  newOrderDetails: newDetailsOrder => {
    const {
      description,

      isFreightInsured,
      noOfHelper,

      pickUpDate,
      pickUpTime,

      rideType
    } = newDetailsOrder

    return {
      type: actions.NEW_ORDER_DETAILS_REQUEST,
      payload: {
        description,

        isFreightInsured,
        noOfHelper,

        pickUpDate,
        pickUpTime,

        rideType
      }
    }
  },
  recieveMessages: msg => ({
    type: actions.RECEIVE_MESSAGES,
    payload: msg
  }),
  recieveMessage: msg => ({
    type: actions.RECEIVE_MESSAGE,
    payload: msg
  }),
  socketConnection: socket => ({
    type: actions.SOCKET_CONNECTION,
    payload: socket
  }),
  editOrder: (feildName, feildsObj) => ({
    type: actions.EDIT_ORDER,
    payload: { feildName, feildsObj }
  }),
  addNewPrice: ({ newPrice, idToken }) => ({
    type: actions.ADD_NEW_PRICE_REQUEST,
    payload: { newPrice, idToken }
  }),
  updatePrice: ({ newPrice, idToken }) => ({
    type: actions.UPDATE_PRICE_REQUEST,
    payload: { newPrice, idToken }
  }),

  orderPriceCalculation: (newOrder, idToken) => ({
    type: actions.ORDER_PRICE_RREQUEST,
    payload: { newOrder, idToken }
  }),
  placeOrderData: placeOrderObj => {
    return {
      type: actions.PLACE_ORDER_DATA,
      payload: { placeOrderObj }
    }
  },
  updateOrderPriceCalculation: (newOrder, idToken) => ({
    type: actions.UPDATE_ORDER_PRICE_REQUEST,
    payload: { newOrder, idToken }
  }),
  newUpdateOrders: (newUpdateOrder, idToken) => ({
    type: actions.NEW_UPDATE_ORDER_REQUEST,
    payload: { newUpdateOrder, idToken }
  }),
  activeUpdateOrders: (newUpdateOrder, idToken) => ({
    type: actions.ACTIVE_UPDATE_ORDER_REQUEST,
    payload: { newUpdateOrder, idToken }
  }),
  pendingUpdateOrders: (newUpdateOrder, idToken) => ({
    type: actions.PENDING_UPDATE_ORDER_REQUEST,
    payload: { newUpdateOrder, idToken }
  }),
  newOrderPrice: (newPrice, id, idToken) => ({
    type: actions.NEW_UPDATE_ORDER_PRICE_REQUEST,
    payload: { newPrice, id, idToken }
  }),
  newActiveOrderPrice: (newPrice, id, idToken) => ({
    type: actions.ACTIVE_UPDATE_ORDER_PRICE_REQUEST,
    payload: { newPrice, id, idToken }
  }),
  newPendingOrderPrice: (newPrice, id, idToken) => ({
    type: actions.PENDING_UPDATE_ORDER_PRICE_REQUEST,
    payload: { newPrice, id, idToken }
  }),

  autoPlaceOrder: (orderId, idToken) => ({
    type: actions.AUTO_PLACE_ORDER,
    payload: { orderId, idToken }
  }),
  manualPlaceOrder: (orderId, driverId, idToken) => ({
    type: actions.MANUAL_PLACE_ORDER,
    payload: { orderId, driverId, idToken }
  }),
  autoSucc: () => ({
    type: actions.AUTO_SUCCESS
  }),
  manualSucc: () => ({
    type: actions.MANUAL_SUCCESS
  }),
  cleanupErrors: () => ({
    type: actions.CLEAR_ERRORS
  })
}

export default actions
