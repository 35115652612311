import actions from './actions'

const initState = {
  driversList: [],
  activeOrdersList: [],
  pendingOrdersList: [],
  completeOrderList: [],
  cancelledOrderList: [],
  pagination: {
    current: 1,
    pageSize: 2,
    total: 0
  },
  visibel: true,
  loading: false,
  driverRequestError: null,
  driverDetails: null,
  roleToken: '',
  docsApproved: null,
  docsRejected: null
}

export default function driversReducer (state = initState, action) {
  switch (action.type) {
    case actions.CREATE_ROLE_SUCCESS: {
      return {
        ...state,
        roleToken: action.roleToken
      }
    }

    case actions.GET_DRIVERS_REQUEST: {
      return {
        ...state,
        // pagination: {
        //   ...state.pagination,
        //   current: action.page,
        //   pageSize: action.numberOfItems
        // },
        loading: true
      }
    }

    case actions.GET_DRIVERS_SUCCESS: {
      return {
        ...state,
        driversList: action.driversList,
        pagination: {
          ...state.pagination,
          total: action.total,
          current: action.page,
          pageSize: action.numberOfItems
        },
        loading: false
      }
    }

    case actions.GET_DRIVER_DETAILS_SUCCESS: {
      return {
        ...state,
        driverDetails: action.driverDetails
      }
    }

    case actions.DOCS_APPROVAL_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actions.ADMIN_BLOCK_DRIVER_SUCCESS: {
      return {
        ...state,
        visibel: false
      }
    }
    case actions.ADMIN_UNBLOCK_DRIVER_SUCCESS: {
      return {
        ...state,
        visibel: true
      }
    }

    case actions.DOCS_APPROVAL_ACCEPTED: {
      return {
        ...state,
        loading: false,
        docsApproved: action.docsSuccessMsg
      }
    }
    case actions.DOCS_APPROVAL_REJECTED: {
      return {
        ...state,
        loading: false,
        docsRejected: action.docsErrorMsg
      }
    }

    case actions.DELETE_DRIVER_SUCCESS: {
      return {
        ...state,
        driverDetails: null
      }
    }

    case actions.CLEAR_ERRORS:
      return {
        ...state,
        driverRequestError: null,
        docsApproved: null,
        docsRejected: null
      }
    case actions.GET_ACTIVE_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actions.GET_ACTIVE_ORDERS_SUCCESS: {
      return {
        ...state,
        activeOrdersList: action.activeOrdersList,
        pagination: {
          ...state.pagination,
          total: action.total,
          current: action.page,
          pageSize: action.numberOfItems
        },
        loading: false
      }
    }
    case actions.GET_PENDING_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actions.GET_PENDING_ORDERS_SUCCESS: {
      return {
        ...state,
        pendingOrdersList: action.pendingOrdersList,
        pagination: {
          ...state.pagination,
          total: action.total,
          current: action.page,
          pageSize: action.numberOfItems
        },
        loading: false
      }
    }
    case actions.GET_COMPLETE_ORDERS_SUCCESS: {
      return {
        ...state,
        completeOrderList: action.completeOrderList,
        pagination: {
          ...state.pagination,
          total: action.total,
          current: action.page,
          pageSize: action.numberOfItems
        },
        loading: false
      }
    }
    case actions.GET_CANCELLED_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actions.GET_CANCELLED_ORDERS_SUCCESS: {
      return {
        ...state,
        cancelledOrderList: action.cancelledOrderList,
        pagination: {
          ...state.pagination,
          total: action.total,
          current: action.page,
          pageSize: action.numberOfItems
        },
        loading: false
      }
    }

    default:
      return state
  }
}
