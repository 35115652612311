import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import axios from 'axios'
import { message } from 'antd'

import { apiURL } from '../../library/helpers/apiURL'
import actions from './actions'
import { startConsecutiveToggle } from 'redux-devtools-log-monitor/lib/actions'

export function * getUsers () {
  yield takeEvery(actions.GET_USERS_REQUEST, function * ({ payload }) {
    const {
      page,
      queryProperty,
      queryValue,
      filterProperty,
      filterValuesList,
      numberOfItems,
      sortBy,
      sortOrder,
      idToken
    } = payload

    const getUserssurl = apiURL + 'getAllUsers'

    try {
      let options = {
        method: 'post',
        data: {
          page,
          queryProperty,
          queryValue,
          filterProperty,
          filterValuesList,
          numberOfItems,
          sortBy,
          sortOrder
        },
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getUserssurl
      }
      const response = yield call(() => axios(options))
      console.log('get all user', response)
      if (response.data.userList.length == 0) {
        message.error('User does not exists.')
      } else {
        yield put({
          type: actions.GET_USERS_SUCCESS,
          usersList: response.data.userList,
          total: response.data.usersTotal,
          page,
          // queryProperty,
          // queryValue,
          // filterProperty,
          // filterValuesList,
          numberOfItems,
          sortBy,
          sortOrder
        })
      }
    } catch (error) {
      console.log('get user error', error.response.data)
      yield put({ type: actions.GET_USERS_ERROR })
    }
  })
}

export function * getUser () {
  yield takeEvery(actions.GET_USER_DETAILS_REQUEST, function * ({ payload }) {
    const { id, idToken } = payload

    const getUserurl = `${apiURL}getUserDetails/${id}`

    console.log(getUserurl)

    try {
      let options = {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: getUserurl
      }
      const response = yield call(() => axios(options))

      console.log(response.data)

      yield put({
        type: actions.GET_USER_DETAILS_SUCCESS,
        userDetails: response.data
      })
    } catch (error) {
      console.log(error.response.data)
      yield put({ type: actions.GET_USER_DETAILS_ERROR })
    }
  })
}
export function * userBlockUnBlock () {
  yield takeEvery(actions.ADMIN_BLOCK_USER_REQUEST, function * ({ payload }) {
    const { id, blockUnBlock, idToken } = payload

    const getUserurl = `${apiURL}userBlockUnBlock`

    try {
      let options = {
        method: 'post',
        url: getUserurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          userId: id,
          allowedresponses: blockUnBlock
        }
      }
      const response = yield call(() => axios(options))

      console.log(response.data)

      if (blockUnBlock === 'Block') {
        message.success(response.data.message)
        yield put({
          type: actions.ADMIN_BLOCK_USER_SUCCESS
        })
      } else {
        message.success(response.data.message)
        yield put({
          type: actions.ADMIN_UNBLOCK_USER_SUCCESS
        })
      }
    } catch (error) {
      console.log(error.response.data)
      message.error(error.response.data.message)
      yield put({ type: actions.ADMIN_BLOCK_USER_ERROR })
    }
  })
}
export function * deleteUser () {
  yield takeEvery(actions.DELETE_USER_REQUEST, function * ({ payload }) {
    const { id, idToken } = payload
    console.log('deletePayload', payload)
    const deleteUserurl = `${apiURL}User/${id}`

    try {
      let options = {
        method: 'delete',
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        url: deleteUserurl
      }
      const response = yield call(() => axios(options))

      console.log(response.data)

      yield put({
        type: actions.DELETE_USER_SUCCESS,
        userDetails: response.data
      })
    } catch (error) {
      console.log(error.response.data.message)
      yield put({ type: actions.DELETE_USER_ERROR })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getUsers),
    fork(getUser),
    fork(deleteUser),
    fork(userBlockUnBlock)
  ])
}
