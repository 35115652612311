import actions from './actions'

const initState = {
  adminsList: [],
  pagination: {
    current: 1,
    pageSize: 2,
    total: 0
  },
  setPasswordSuccess: false,
  chickCurrentPass: false,
  checkNewPhone: false,
  oldPhone: null,
  newOTPRequestError: null,
  phoneVerifyError: null,
  updatePhoneSuccess: false,
  updatePhoneError: false,
  newOTPSuccess: false,
  oldPassword: null,
  loading: false,
  adminRequestError: null,
  adminDetails: null,
  userProfile: null,
  updateNameAndEmail: null,
  roleToken: '',
  updateNameAndEmailError: null
}

export default function adminsReducer (state = initState, action) {
  switch (action.type) {
    case actions.CREATE_ROLE_SUCCESS: {
      return {
        ...state,
        roleToken: action.roleToken
      }
    }

    case actions.GET_ADMINS_REQUEST: {
      return {
        ...state,
        // pagination: {
        //   ...state.pagination,
        //   current: action.page,
        //   pageSize: action.numberOfItems
        // },
        loading: true
      }
    }

    case actions.GET_ADMINS_SUCCESS: {
      return {
        ...state,
        adminsList: action.adminsList,
        pagination: {
          ...state.pagination,
          total: action.total,
          current: action.page,
          pageSize: action.numberOfItems
        },
        loading: false
      }
    }

    case actions.GET_ADMIN_DETAILS_SUCCESS: {
      return {
        ...state,
        adminDetails: action.adminDetails
      }
    }

    case actions.DELETE_ADMIN_SUCCESS: {
      return {
        ...state,
        adminDetails: null
      }
    }
    case actions.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.userProfile
      }
    case actions.UPDATE_NAME_AND_EMAIL_SUCCESS: {
      let { name, email } = action.updateUserProfile
      console.log(name, email)
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          name: name,
          email: email
        }
      }
    }

    case actions.CLEAR_ERRORS:
      return {
        ...state,
        adminRequestError: null
      }
    case actions.CURRENT_PASS_SUCCESS: {
      console.log('currentPassSuccess', action.oldPassword)
      return {
        ...state,
        oldPassword: action.oldPassword,
        chickCurrentPass: true
      }
    }
    case actions.CURRENT_PASS_ERROR: {
      return {
        ...state,
        chickCurrentPass: false,
        loginError: action.error
      }
    }
    case actions.CHCIK_CURRENT_PASS_STATE: {
      return {
        ...state,
        chickCurrentPass: false
      }
    }
    case actions.UPDATE_PASS_SUCCESS:
      return {
        ...state,
        chickCurrentPass: false,
        setPasswordSuccess: true,
        oldPassword: action.oldPassword
      }
    case actions.LOGIN_USER_NEW_OTP_SUCCESS: {
      console.log('newPhone', action.oldPhone)
      return {
        ...state,
        oldPhone: action.oldPhone,
        newOTPRequestError: null,
        newOTPSuccess: true,
        checkNewPhone: true
      }
    }
    case actions.ENTER_OTP_STATE: {
     
      return {
        ...state,

        checkNewPhone: false
      }
    }
    case actions.LOGIN_USER_NEW_OTP_ERROR: {
      let message = action.error.message
      let newState = {
        ...state,
        newOTPSuccess: false,
        checkNewPhone: false,
        newOTPRequestError: 'An error occured, try again later'
      }

      if (message === 'WAIT_60_SECONDS_FOR_NEW_TOKEN') {
        newState = {
          ...newState,
          newOTPRequestError:
            'Please wait 60 seconds before requesting a new token'
        }
      }

      return newState
    }
    case actions.UPDATE_PHONE_LOGIN_USER_SUCCESS: {
      return {
        ...state,

        updatePhoneSuccess: true,
        checkNewPhone: false
      }
    }

    case actions.UPDATE_PHONE_LOGIN_USER_ERROR: {
      let message = action.error.message
      let newState = {
        ...state,
        updatePhoneError: 'An error occured, please try again later'
      }

      if (message === 'PHONE_IS_NOT_AVAILABLE') {
        newState = {
          ...newState,
          updatePhoneError: 'Entered phone number is not available'
        }
      } else if (message === 'WAIT_60_SECONDS_FOR_PHONE_UPDATE') {
        newState = {
          ...newState,
          updatePhoneError:
            'Please wait 60 seconds before trying to update phone number'
        }
      }

      return newState
    }

    default:
      return state
  }
}
