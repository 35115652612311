const actions = {
  GET_DRIVERS_REQUEST: 'GET_DRIVERS_REQUEST',
  GET_DRIVERS_SUCCESS: 'GET_DRIVERS_SUCCESS',
  GET_DRIVERS_ERROR: 'GET_DRIVERS_ERROR',
  GET_DRIVER_DETAILS_REQUEST: 'GET_DRIVER_DETAILS_REQUEST',
  GET_DRIVER_DETAILS_SUCCESS: 'GET_DRIVER_DETAILS_SUCCESS',
  GET_DRIVER_DETAILS_ERROR: 'GET_DRIVER_DETAILS_ERROR',
  DELETE_DRIVER_REQUEST: 'DELETE_DRIVER_REQUEST',
  DELETE_DRIVER_SUCCESS: 'DELETE_DRIVER_SUCCESS',
  DELETE_DRIVER_ERROR: 'DELETE_DRIVER_ERROR',
  DOCS_APPROVAL_REQUEST: 'DOCS_APPROVAL_REQUEST',
  DOCS_APPROVAL_ACCEPTED: 'DOCS_APPROVAL_ACCEPTED',
  DOCS_APPROVAL_REJECTED: 'DOCS_APPROVAL_REJECTED',

  GET_ACTIVE_ORDERS_REQUEST: 'GET_ACTIVE_ORDERS_REQUEST',
  GET_ACTIVE_ORDERS_SUCCESS: 'GET_ACTIVE_ORDERS_SUCCESS',

  GET_PENDING_ORDERS_REQUEST: 'GET_PENDING_ORDERS_REQUEST',
  GET_PENDING_ORDERS_SUCCESS: 'GET_PENDING_ORDERS_SUCCESS',

  GET_COMPLETE_ORDERS_REQUEST: 'GET_COMPLETE_ORDERS_REQUEST',
  GET_COMPLETE_ORDERS_SUCCESS: 'GET_COMPLETE_ORDERS_SUCCESS',

  GET_CANCELLED_ORDERS_REQUEST: 'GET_CANCELLED_ORDERS_REQUEST',
  GET_CANCELLED_ORDERS_SUCCESS: 'GET_CANCELLED_ORDERS_SUCCESS',

  GET_ACTIVE_ORDER_DETAILS_REQUEST: 'GET_ACTIVE_ORDER_DETAILS_REQUEST',
  GET_ACTIVE_ORDER_DETAILS_SUCCESS: 'GET_ACTIVE_ORDER_DETAILS_SUCCESS',
  GET_ACTIVE_ORDER_DETAILS_ERROR: 'GET_ACTIVE_ORDER_DETAILS_ERROR',

  GET_PENDING_ORDER_DETAILS_REQUEST: 'GET_PENDING_ORDER_DETAILS_REQUEST',
  GET_PENDING_ORDER_DETAILS_SUCCESS: 'GET_PENDING_ORDER_DETAILS_SUCCESS',
  GET_PENDING_ORDER_DETAILS_ERROR: 'GET_PENDING_ORDER_DETAILS_ERROR',

  GET_COMPLETE_ORDER_DETAILS_REQUEST: 'GET_COMPLETE_ORDER_DETAILS_REQUEST',
  GET_COMPLETE_ORDER_DETAILS_SUCCESS: 'GET_COMPLETE_ORDER_DETAILS_SUCCESS',
  GET_COMPLETE_ORDER_DETAILS_ERROR: 'GET_COMPLETE_ORDER_DETAILS_ERROR',

  GET_CANCELLED_ORDER_DETAILS_REQUEST: 'GET_CANCELLED_ORDER_DETAILS_REQUEST',
  GET_CANCELLED_ORDER_DETAILS_SUCCESS: 'GET_CANCELLED_ORDER_DETAILS_SUCCESS',
  GET_CANNCELLED_ORDER_DETAILS_ERROR: 'GET_CANNCELLED_ORDER_DETAILS_ERROR',

  ADMIN_BLOCK_DRIVER_REQUEST: 'ADMIN_BLOCK_DRIVER_REQUEST',
  ADMIN_BLOCK_DRIVER_SUCCESS: 'ADMIN_BLOCK_DRIVER_SUCCESS',
  ADMIN_UNBLOCK_DRIVER_SUCCESS: 'ADMIN_UNBLOCK_DRIVER_SUCCESS',
  ADMIN_BLOCK_DRIVER_ERROR: 'ADMIN_BLOCK_DRIVER_ERROR',

  CLEAR_ERRORS: 'CLEAR_ERRORS',
  getDrivers: ({
    page,
    queryProperty,
    queryValue,
    filterProperty,
    filterValuesList,
    numberOfItems,
    sortBy,
    sortOrder,
    idToken,
    activityStatus
  }) => {
    page = !page ? '1' : page
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue
    numberOfItems = !numberOfItems ? '' : numberOfItems
    sortBy = !sortBy ? '' : sortBy
    sortOrder = !sortOrder ? '' : sortOrder

    return {
      type: actions.GET_DRIVERS_REQUEST,
      payload: {
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken,
        activityStatus
      }
    }
  },

  getActiveOrders: ({
    driverId,
    page,
    queryProperty,
    queryValue,
    filterProperty,
    filterValuesList,
    numberOfItems,
    sortBy,
    sortOrder,
    idToken
  }) => {
    page = !page ? '1' : page
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue
    numberOfItems = !numberOfItems ? '' : numberOfItems
    sortBy = !sortBy ? '' : sortBy
    sortOrder = !sortOrder ? '' : sortOrder

    return {
      type: actions.GET_ACTIVE_ORDERS_REQUEST,
      payload: {
        driverId,
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken
      }
    }
  },
  getPendingOrders: ({
    driverId,
    page,
    queryProperty,
    queryValue,
    filterProperty,
    filterValuesList,
    numberOfItems,
    sortBy,
    sortOrder,
    idToken
  }) => {
    page = !page ? '1' : page
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue
    numberOfItems = !numberOfItems ? '' : numberOfItems
    sortBy = !sortBy ? '' : sortBy
    sortOrder = !sortOrder ? '' : sortOrder

    return {
      type: actions.GET_PENDING_ORDERS_REQUEST,
      payload: {
        driverId,
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken
      }
    }
  },
  getCompleteOrders: ({
    driverId,
    page,
    queryProperty,
    queryValue,
    filterProperty,
    filterValuesList,
    numberOfItems,
    sortBy,
    sortOrder,
    idToken
  }) => {
    page = !page ? '1' : page
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue
    numberOfItems = !numberOfItems ? '' : numberOfItems
    sortBy = !sortBy ? '' : sortBy
    sortOrder = !sortOrder ? '' : sortOrder

    return {
      type: actions.GET_COMPLETE_ORDERS_REQUEST,
      payload: {
        driverId,
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken
      }
    }
  },
  getCancelledOrders: ({
    driverId,
    page,
    queryProperty,
    queryValue,
    filterProperty,
    filterValuesList,
    numberOfItems,
    sortBy,
    sortOrder,
    idToken
  }) => {
    page = !page ? '1' : page
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue
    numberOfItems = !numberOfItems ? '' : numberOfItems
    sortBy = !sortBy ? '' : sortBy
    sortOrder = !sortOrder ? '' : sortOrder

    return {
      type: actions.GET_CANCELLED_ORDERS_REQUEST,
      payload: {
        driverId,
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken
      }
    }
  },

  getDriver: ({ id, idToken }) => ({
    type: actions.GET_DRIVER_DETAILS_REQUEST,
    payload: { id, idToken }
  }),
  driverBlockUnBlock: ({ id, blockUnBlock, idToken }) => ({
    type: actions.ADMIN_BLOCK_DRIVER_REQUEST,
    payload: { id, blockUnBlock, idToken }
  }),

  deleteDriver: ({ id, idToken }) => ({
    type: actions.DELETE_DRIVER_REQUEST,
    payload: { id, idToken }
  }),

  setDocsApprovalStatus: ({ id, idToken, approvalStatus, adminComment }) => ({
    type: actions.DOCS_APPROVAL_REQUEST,
    payload: { id, idToken, approvalStatus, adminComment }
  }),

  cleanupErrors: () => ({
    type: actions.CLEAR_ERRORS
  })
}
export default actions
