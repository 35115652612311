import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import GlobalStyles from './assets/styles/globalStyle'
import { store,persistor } from './redux/store'
import Boot from './redux/boot'
import Routes from './router'
import AppProvider from './AppProvider'



const App = () => (

  
  <Provider store={store}>
    <PersistGate  persistor={persistor}>
    <AppProvider>
      <>
     
        <GlobalStyles />
       
        <Routes />
       
      </>
    </AppProvider>
    </PersistGate>
  </Provider>
)
Boot()
  .then(() => App())
  .catch(error => console.error(error))

export default App
//frentend data