import React, { lazy, Suspense } from 'react'
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import ErrorBoundary from './ErrorBoundary'
import { PUBLIC_ROUTE } from './route.constants'
import Loader from './components/utility/loader'

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'))

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('./containers/Pages/SignIn/SignIn'))
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('./containers/Pages/404/404'))
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('./containers/Pages/500/500'))
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('./containers/Pages/SignIn/SignIn'))
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('./containers/Pages/SignUp/SignUp'))
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import('./containers/Pages/ForgotPassword/ForgotPassword')
    )
  },
  {
    path: PUBLIC_ROUTE.OTP,
    component: lazy(() => import('./containers/Pages/OTP/OTP'))
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import('./containers/Pages/ResetPassword/ResetPassword')
    )
  },
  {
    path: PUBLIC_ROUTE.SET_PASSWORD,
    component: lazy(() => import('./containers/Pages/SetPassword/SetPassword'))
  }
]

function PrivateRoute ({ children, ...rest }) {
  const isJWTSet = useSelector(state => state.Auth.idToken)
  const isLoggedInVal = useSelector(state => state.Auth.isLoggedIn)
  const isLoggedIn = isJWTSet && isJWTSet !== '' && isLoggedInVal

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
export default function Routes () {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}

            <PrivateRoute path='/dashboard'>
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  )
}
