import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import persistState from 'redux-localstorage'
import storage from 'redux-persist/lib/storage'
import rootReducer from './root-reducer'
import rootSaga from './root-saga'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [thunk, sagaMiddleware]

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose
    const persistConfig = {
      key: 'root',
     storage
    }
    const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(...middlewares),
    persistState(['Auth', 'ManageAdmins'])
  )
)
let persistor = persistStore(store)
sagaMiddleware.run(rootSaga)
export { store,persistor }
