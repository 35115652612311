const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERROR: 'REGISTER_ERROR',
  PHONE_VERIFY_REQUEST: 'PHONE_VERIFY_REQUEST',
  PHONE_VERIFY_SUCCESS: 'PHONE_VERIFY_SUCCESS',
  PHONE_VERIFY_ERROR: 'PHONE_VERIFY_ERROR',
  NEW_OTP_REQUEST: 'NEW_OTP_REQUEST',
  NEW_OTP_SUCCESS: 'NEW_OTP_SUCCESS',
  NEW_OTP_ERROR: 'NEW_OTP_ERROR',
  SET_PASS_REQUEST: 'SET_PASS_REQUEST',
  SET_PASS_SUCCESS: 'SET_PASS_SUCCESS',
  SET_PASS_ERROR: 'SET_PASS_ERROR',
  UPDATE_PHONE_REQUEST: 'UPDATE_PHONE_REQUEST',
  UPDATE_PHONE_SUCCESS: 'UPDATE_PHONE_SUCCESS',
  UPDATE_PHONE_ERROR: 'UPDATE_PHONE_ERROR',
  USER_ME_REQUEST: 'USER_ME_REQUEST',
  USER_ME_SUCCESS: 'USER_ME_SUCCESS',
  USER_ME_ERROR: 'USER_ME_ERROR',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: ({ username, password }) => ({
    type: actions.LOGIN_REQUEST,
    payload: { username, password }
  }),
  meUser: ({ idToken }) => ({
    type: actions.USER_ME_REQUEST,
    payload: { idToken }
  }),
  logout: () => ({
    type: actions.LOGOUT
  }),
  register: ({ name, email, phone, roleToken }) => ({
    type: actions.REGISTER_REQUEST,
    payload: { name, email, phone, roleToken }
  }),
  phoneVerify: ({ otp, phone }) => ({
    type: actions.PHONE_VERIFY_REQUEST,
    payload: { otp, phone }
  }),
  requestNewOTP: ({ phone }) => ({
    type: actions.NEW_OTP_REQUEST,
    payload: { phone }
  }),
  setPassword: ({ password, passOTP, idToken, phone }) => ({
    type: actions.SET_PASS_REQUEST,
    payload: { password, passOTP, idToken, phone }
  }),
  updatePhone: ({ phone, newPhone }) => ({
    type: actions.UPDATE_PHONE_REQUEST,
    payload: { phone, newPhone }
  }),

  cleanupErrors: () => ({
    type: actions.CLEAR_ERRORS
  })
}
export default actions
