import { all } from 'redux-saga/effects'
import authSaga from './auth/saga'
import ecommerceSaga from './ecommerce/saga'
import manageAdmins from './manageAdmins/saga'
import manageDrivers from './manageDrivers/saga'
import manageOrders from './manageOrders/saga'
import manageUsers from './manageUsers/saga'
import chatSagas from './chat/sagas';

export default function * rootSaga (getState) {
  yield all([authSaga(), ecommerceSaga(), manageAdmins(), manageDrivers(), manageOrders(), manageUsers(), chatSagas()])
}

// chatSagas()