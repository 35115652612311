import actions from './actions'
import { loginError, meUser } from './saga'

const initState = {
  idToken: null,
  isLoggedIn: false,
  registerStep: null,
  phone: '',
  passOTP: '',
  loginError: null,
  registerError: null,
  newOTPRequestError: null,
  phoneVerifyError: null,
  newOTPSuccess: false,
  updatePhoneSuccess: false,
  updatePhoneError: null,
  setPasswordSuccess: false,
  roles: null,
  userProfile: null
}

export default function authReducer (state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...initState,
        idToken: action.token,
        isLoggedIn: true,
        roles: action.roles
      }
    case actions.LOGIN_ERROR: {
      return {
        ...state,
        loginError: action.error
      }
    }
    case actions.LOGOUT:
      return initState

    case actions.REGISTER_SUCCESS:
      return {
        ...state,
        phone: action.phone,
        registerStep: 1
      }

    case actions.REGISTER_ERROR: {
      let message = action.error.message
      let newState = {
        ...state,
        registerError: 'An error occured, try again later'
      }

      if (message === 'USER_ALREADY_REGISTERED_AND_VERIFIED') {
        newState = {
          ...newState,
          registerError:
            'A user with same phone/email is already registered, try signing in instead'
        }
      } else if (message === 'INVALID_ROLE_TOKEN') {
        newState = {
          ...newState,
          registerError: 'Incorrect role token'
        }
      } else if (message === 'USER_ALREADY_REGISTERED_AND_UNVERIFIED') {
        newState = {
          ...newState,
          registerError: 'Verify phone number',
          registerStep: 1,
          phone: action.phone
        }
      }

      return newState
    }

    case actions.NEW_OTP_SUCCESS: {
      return {
        ...state,
        newOTPRequestError: null,
        phoneVerifyError: null,
        newOTPSuccess: true
      }
    }

    case actions.NEW_OTP_ERROR: {
      let message = action.error.message
      let newState = {
        ...state,
        newOTPSuccess: false,
        newOTPRequestError: 'An error occured, try again later'
      }

      if (message === 'WAIT_60_SECONDS_FOR_NEW_TOKEN') {
        newState = {
          ...newState,
          newOTPRequestError:
            'Please wait 60 seconds before requesting a new token'
        }
      }

      return newState
    }

    case actions.PHONE_VERIFY_SUCCESS:
      return {
        ...state,
        registerStep: 2,
        idToken: action.jwt,
        passOTP: action.otp
      }
    case actions.USER_ME_SUCCESS:
      return {
        ...state,
        userProfile: action.userProfile
      }

    case actions.PHONE_VERIFY_ERROR: {
      let message = action.error.message
      let newState = {
        ...state,
        phoneVerifyError: 'An error occured, please try again later'
      }

      if (message === 'INVALID_TOKEN') {
        newState = {
          ...newState,
          phoneVerifyError: 'Incorrect token entered, please try again'
        }
      } else if (message === 'LATE_VERIFICATION') {
        newState = {
          ...newState,
          phoneVerifyError: 'Late verification, try again with a new token'
        }
      }

      return newState
    }

    case actions.UPDATE_PHONE_SUCCESS: {
      return {
        ...state,
        phone: action.newPhone,
        updatePhoneSuccess: true,
        newOTPSuccess: true,
        newOTPRequestError: null,
        phoneVerifyError: null
      }
    }

    case actions.UPDATE_PHONE_ERROR: {
      let message = action.error.message
      let newState = {
        ...state,
        updatePhoneError: 'An error occured, please try again later'
      }

      if (message === 'PHONE_IS_NOT_AVAILABLE') {
        newState = {
          ...newState,
          updatePhoneError: 'Entered phone number is not available'
        }
      } else if (message === 'WAIT_60_SECONDS_FOR_PHONE_UPDATE') {
        newState = {
          ...newState,
          updatePhoneError:
            'Please wait 60 seconds before trying to update phone number'
        }
      }

      return newState
    }

    case actions.SET_PASS_SUCCESS:
      return {
        ...state,
        registerStep: null,
        setPasswordSuccess: true
      }
    case actions.CLEAR_ERRORS:
      return {
        ...state,
        registerError: null,
        newOTPRequestError: null,
        phoneVerifyError: null,
        updatePhoneSuccess: false,
        updatePhoneError: null,
        loginError: null
      }
    default:
      return state
  }
}
