import { combineReducers } from 'redux'
import App from './app/reducer'
import Auth from './auth/reducer'
import ThemeSwitcher from './themeSwitcher/reducer'
import Ecommerce from './ecommerce/reducer'
import LanguageSwitcher from './languageSwitcher/reducer'
import ManageAdmins from './manageAdmins/reducer'
import ManageDrivers from './manageDrivers/reducer'
import ManageOrders from './manageOrders/reducer'
import ManageUsers from './manageUsers/reducer'
import Chat from './chat/reducers';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  Ecommerce,
  LanguageSwitcher,
  ManageAdmins,
  ManageDrivers,
  ManageOrders,
  ManageUsers,
  Chat
})
