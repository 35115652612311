import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import { createBrowserHistory } from 'history'
import axios from 'axios'
import { message } from 'antd'
import { getToken, clearToken } from '../../library/helpers/utility'
import { apiURL } from '../../library/helpers/apiURL'
import actions from './actions'

const history = createBrowserHistory()

export function * loginRequest () {
  yield takeEvery(actions.LOGIN_REQUEST, function * ({ payload }) {
    const { username, password } = payload
    const loginurl = apiURL + 'login '
    console.log(payload)

    try {
      let options = {
        method: 'post',
        url: loginurl,
        data: { username: username, password: password }
      }
      const response = yield call(() => axios(options))

      console.log(response)

      yield put({
        type: actions.LOGIN_SUCCESS,
        token: response.data.jwt,
        roles: response.data.roles
      })
    } catch (error) {
      console.log('error', error.response.data)
      
      
      yield put({
        type: actions.LOGIN_ERROR,
        error: error.response.data.message
          ? error.response.data.message
          : 'An error occured'
      })
    }
  })
}

// export function * loginSuccess () {
//   yield takeEvery(actions.LOGIN_SUCCESS, function * (payload) {
//     yield localStorage.setItem('id_token', payload.token)
//   })
// }

// export function * loginError () {
//   yield takeEvery(actions.LOGIN_ERROR, function * () {})
// }

export function * registerRequest () {
  yield takeEvery('REGISTER_REQUEST', function * ({ payload }) {
    const { name, email, phone, roleToken } = payload
    const registerurl = apiURL + 'register'
    console.log(payload)
    try {
      let options = {
        method: 'post',
        url: registerurl,
        data: { name, email, phone, roleToken }
      }
      const response = yield call(() => axios(options))

      console.log(response)

      yield put({
        type: actions.REGISTER_SUCCESS,
        phone: phone
      })
    } catch (error) {
      console.log(error.response.data)
      yield put({
        type: actions.REGISTER_ERROR,
        error: error.response.data ? error.response.data : 'An error occured',
        phone: phone
      })
    }
  })
}

export function * phoneVerifyRequest () {
  yield takeEvery('PHONE_VERIFY_REQUEST', function * ({ payload }) {
    const { otp, phone } = payload
    const phoneverifyurl = apiURL + 'verifyToken '
    console.log(payload)
    try {
      let options = {
        method: 'post',
        url: phoneverifyurl,
        data: { username: phone, token: otp }
      }
      const response = yield call(() => axios(options))

      console.log(response)

      yield put({
        type: actions.PHONE_VERIFY_SUCCESS,
        jwt: response.data.jwt,
        otp
      })
    } catch (error) {
      console.log(error)
      yield put({
        type: actions.PHONE_VERIFY_ERROR,
        error: error.response.data ? error.response.data : 'An error occured'
      })
    }
  })
}

export function * newOTPRequest () {
  yield takeEvery('NEW_OTP_REQUEST', function * ({ payload }) {
    const { phone } = payload
    const newOTPRequesturl = apiURL + 'requestToken '
    console.log(payload)
    try {
      let options = {
        method: 'post',
        url: newOTPRequesturl,
        data: { username: phone }
      }
      const response = yield call(() => axios(options))

      console.log(response)

      yield put({
        type: actions.NEW_OTP_SUCCESS
      })
    } catch (error) {
      console.log(error)
      yield put({
        type: actions.NEW_OTP_ERROR,
        error: error.response.data ? error.response.data : 'An error occured'
      })
    }
  })
}

export function * setPassRequest () {
  yield takeEvery('SET_PASS_REQUEST', function * ({ payload }) {
    const { password, passOTP, idToken, phone } = payload
    const phoneverifyurl = apiURL + 'changePassword '
    console.log(payload)
    try {
      let options = {
        method: 'post',
        url: phoneverifyurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: { newPassword: password, oldPassword: '', token: passOTP }
      }
      const response = yield call(() => axios(options))

      console.log(response)

      yield put({
        type: actions.SET_PASS_SUCCESS
      })
    } catch (error) {
      console.log(error)
      yield put({ type: actions.SET_PASS_ERROR })
    }
  })
}

export function * updatePhone () {
  yield takeEvery('UPDATE_PHONE_REQUEST', function * ({ payload }) {
    const { phone, newPhone } = payload
    const updatePhoneurl = apiURL + 'updatePhoneNewUser '
    console.log(payload)
    try {
      let options = {
        method: 'post',
        url: updatePhoneurl,
        data: { oldPhone: phone, newPhone }
      }
      const response = yield call(() => axios(options))

      console.log(response)

      yield put({
        type: actions.UPDATE_PHONE_SUCCESS,
        newPhone
      })
    } catch (error) {
      console.log(error)
      yield put({
        type: actions.UPDATE_PHONE_ERROR,
        error: error.response.data ? error.response.data : 'An error occured'
      })
    }
  })
}
export function * meUser () {
  yield takeEvery('USER_ME_REQUEST', function * ({ payload }) {
    const { idToken } = payload
    const meurl = apiURL + 'me '
    console.log(payload)
    try {
      let options = {
        method: 'get',
        url: meurl,
        headers: {
          Authorization: 'Bearer ' + idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
      const response = yield call(() => axios(options))

      console.log(response)
      if (response.status == '200') {
        yield put({
          type: actions.USER_ME_SUCCESS,
          userProfile: response.data
        })
      }
    } catch (error) {
      console.log(error)
      message.error(error.response.data.message)
      yield put({
        type: actions.USER_ME_ERROR,
        error: error.response.data ? error.response.data : 'An error occured'
      })
    }
  })
}
export function * logout () {
  yield takeEvery(actions.LOGOUT, function * () {
    clearToken()
    history.push('/')
    yield put({
      type: actions.LOGOUT_SUCCESS
    })
  })
}
export function * checkAuthorization () {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function * () {
    const token = getToken().get('idToken')
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile'
      })
    }
  })
}
export default function * rootSaga () {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    // fork(loginSuccess),
    // fork(loginError),
    fork(logout),
    fork(registerRequest),
    fork(phoneVerifyRequest),
    fork(setPassRequest),
    fork(newOTPRequest),
    fork(updatePhone),
    fork(meUser)
  ])
}
