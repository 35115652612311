const actions = {
  CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_ERROR: 'CREATE_ROLE_ERROR',
  GET_ADMINS_REQUEST: 'GET_ADMINS_REQUEST',
  GET_ADMINS_SUCCESS: 'GET_ADMINS_SUCCESS',
  GET_ADMINS_ERROR: 'GET_ADMINS_ERROR',
  GET_ADMIN_DETAILS_REQUEST: 'GET_ADMIN_DETAILS_REQUEST',
  GET_ADMIN_DETAILS_SUCCESS: 'GET_ADMIN_DETAILS_SUCCESS',
  GET_ADMIN_DETAILS_ERROR: 'GET_ADMIN_DETAILS_ERROR',
  DELETE_ADMIN_REQUEST: 'DELETE_ADMIN_REQUEST',
  DELETE_ADMIN_SUCCESS: 'DELETE_ADMIN_SUCCESS',
  DELETE_ADMIN_ERROR: 'DELETE_ADMIN_ERROR',
  GET_USER_PROFILE_REQUEST: ' GET_USER_PROFILE_REQUEST',
  GET_USER_PROFILE_SUCCESS: ' GET_USER_PROFILE_SUCCES',
  GET_USER_PROFILE_ERROR: ' GET_USER_PROFILE_ERROR',
  UPDATE_NAME_AND_EMAIL_REQUEST: 'UPDATE_NAME_AND_EMAIL_REQUEST',
  UPDATE_NAME_AND_EMAIL_SUCCESS: ' UPDATE_NAME_AND_EMAIL_SUCCESS',
  UPDATE_NAME_AND_EMAIL_ERROR: ' UPDATE_NAME_AND_EMAIL_ERROR',
  CURRENT_PASS_REQUEST: 'CURRENT_PASS_REQUEST',
  CURRENT_PASS_SUCCESS: 'CURRENT_PASS_SUCCESS',

  CURRENT_PASS_ERROR: 'CURRENT_PASS_ERROR',
  UPDATE_PASS_REQUEST: 'UPDATE_PASS_REQUEST',
  UPDATE_PASS_SUCCESS: 'UPDATE_PASS_SUCCESS',
  UPDATE_PASS_ERROR: 'UPDATE_PASS_ERROR',
  LOGIN_USER_NEW_OTP_REQUEST: 'LOGIN_USER_NEW_OTP_REQUEST',
  LOGIN_USER_NEW_OTP_SUCCESS: 'LOGIN_USER_NEW_OTP_SUCCESS',
  LOGIN_USER_NEW_OTP_ERROR: 'LOGIN_USER_NEW_OTP_ERROR',
  UPDATE_PHONE_LOGIN_USER_REQUEST: 'UPDATE_PHONE_LOGIN_USER_REQUEST',
  UPDATE_PHONE_LOGIN_USER_SUCCESS: 'UPDATE_PHONE_LOGIN_USER_SUCCESS',
  UPDATE_PHONE_LOGIN_USER_ERROR: 'UPDATE_PHONE_LOGIN_USER_ERROR',
  TO_UPDATE_ADMIN_PROFILE_REQUEST: 'TO_UPDATE_ADMIN_PROFILE_REQUEST',
  TO_UPDATE_ADMIN_PROFILE_SUCCESS: 'TO_UPDATE_ADMIN_PROFILE_SUCCESS',
  TO_UPDATE_ADMIN_PROFILE_ERROR: 'TO_UPDATE_ADMIN_PROFILE_ERROR',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  CHCIK_CURRENT_PASS_STATE: 'CHCIK_CURRENT_PASS_STATE',
  ENTER_OTP_STATE: 'ENTER_OTP_STATE',

  createAdminRole: ({ roles, idToken }) => ({
    type: actions.CREATE_ROLE_REQUEST,
    payload: { roles, idToken }
  }),
  getAdmins: ({
    page,
    queryProperty,
    queryValue,
    filterProperty,
    filterValuesList,
    numberOfItems,
    sortBy,
    sortOrder,
    idToken
  }) => {
    page = !page ? '1' : page
    queryProperty = !queryProperty ? '' : queryProperty
    queryValue = !queryValue ? '' : queryValue
    numberOfItems = !numberOfItems ? '' : numberOfItems
    sortBy = !sortBy ? '' : sortBy
    sortOrder = !sortOrder ? '' : sortOrder

    return {
      type: actions.GET_ADMINS_REQUEST,
      payload: {
        page,
        queryProperty,
        queryValue,
        filterProperty,
        filterValuesList,
        numberOfItems,
        sortBy,
        sortOrder,
        idToken
      }
    }
  },
  getAdmin: ({ id, idToken }) => ({
    type: actions.GET_ADMIN_DETAILS_REQUEST,
    payload: { id, idToken }
  }),

  toUpdateAdminsProfile: ({
    userId,
    newPhone,
    newPassword,
    newRoles,
    idToken
  }) => ({
    type: actions.TO_UPDATE_ADMIN_PROFILE_REQUEST,
    payload: { userId, newPhone, newPassword, newRoles, idToken }
  }),
  deleteAdmin: ({ id, idToken }) => ({
    type: actions.DELETE_ADMIN_REQUEST,
    payload: { id, idToken }
  }),
  getProfile: ({ idToken }) => ({
    type: actions.GET_USER_PROFILE_REQUEST,
    payload: { idToken }
  }),
  updateNameAndEmail: ({ name, email, idToken }) => ({
    type: actions.UPDATE_NAME_AND_EMAIL_REQUEST,
    payload: { name, email, idToken }
  }),
  currentPassRequest: ({ username, password }) => ({
    type: actions.CURRENT_PASS_REQUEST,
    payload: { username, password }
  }),
  updatePassRequest: ({ newPassword, oldPassword, idToken }) => ({
    type: actions.UPDATE_PASS_REQUEST,
    payload: { newPassword, oldPassword, idToken }
  }),
  chickCurrentPassState: () => ({
    type: actions.CHCIK_CURRENT_PASS_STATE
  }),
  enterOtpState: () => ({
    type: actions.ENTER_OTP_STATE
  }),

  loginUserRequestNewOTP: ({ newPhone, idToken }) => ({
    type: actions.LOGIN_USER_NEW_OTP_REQUEST,
    payload: { newPhone, idToken }
  }),
  updatePhoneLoginUser: ({ oldPhone, newOtp, idToken }) => ({
    type: actions.UPDATE_PHONE_LOGIN_USER_REQUEST,
    payload: { oldPhone, newOtp, idToken }
  }),
  cleanupErrors: () => ({
    type: actions.CLEAR_ERRORS
  })
}
export default actions
