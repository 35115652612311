import actions from './actions'

const initState = {
  usersList: [],
  pagination: {
    current: 1,
    pageSize: 2,
    total: 0
  },
  visibel: true,
  loading: false,
  userRequestError: null,
  userDetails: null,
  roleToken: ''
}

export default function usersReducer (state = initState, action) {
  switch (action.type) {
    case actions.CREATE_ROLE_SUCCESS: {
      return {
        ...state,
        roleToken: action.roleToken
      }
    }

    case actions.GET_USERS_REQUEST: {
      return {
        ...state,
        // pagination: {
        //   ...state.pagination,
        //   current: action.page,
        //   pageSize: action.numberOfItems
        // },
        loading: true
      }
    }
    case actions.ADMIN_BLOCK_USER_SUCCESS: {
      return {
        ...state,
        visibel: false
      }
    }
    case actions.ADMIN_UNBLOCK_USER_SUCCESS: {
      return {
        ...state,
        visibel: true
      }
    }
    case actions.GET_USERS_SUCCESS: {
      return {
        ...state,
        usersList: action.usersList,
        pagination: {
          ...state.pagination,
          total: action.total,
          current: action.page,
          pageSize: action.numberOfItems
        },
        loading: false
      }
    }

    case actions.GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userDetails: action.userDetails
      }
    }

    case actions.DELETE_USER_SUCCESS: {
      return {
        ...state,
        userDetails: null
      }
    }

    case actions.CLEAR_ERRORS:
      return {
        ...state,
        userRequestError: null
      }

    default:
      return state
  }
}
